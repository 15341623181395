import React, { useEffect, useState, useContext } from 'react'
import './single.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import Chart from '../../../components/chart/Chart'
import { doc, getDoc, getDocs, where, collection, query } from "firebase/firestore"
import { useHistory, useParams } from "react-router-dom"
import { db } from '../../../firebase'
import ApplicantTable from '../../../components/table/ApplicantTable'
import { AuthContext } from "../../../context/AuthContext";
import DataTableUserApplication from "../../../components/dataTable/DataTableUserApplication"

const Single = ({user}) => {

  const {applicantId} = useParams()

  // const { currentUser } = useContext(AuthContext)


  const [ userData, setUserData ] = useState("")

  const [ applicationData, setapplicationData ] = useState([])

  const getApplicant = async() => {

      const docRef = doc(db, "travellers", applicantId);

      const docSnap = await getDoc(docRef);

    
      
      if (docSnap.exists()) {

        setUserData({...docSnap.data(), date: docSnap.data().timeStamp.toDate().toDateString()})
        // console.log(userData)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
  }

  useEffect(()=>{
    getApplicant()
  }, [])


 

  return (
    <div className='single'>
      <Sidebar/>
      <div className='singleContainer'>
        <Navbar/>
        <div className="top">
          <div className="left">
            {/* <div className="editbutton">Edit</div> */}
            <h3 className='itemTitle'>{userData.firstname} {userData.othername} {userData.surname}</h3>
            <div className='item'>
            <img
                src = {userData.img ? userData.img : "../images/user.png"}
                alt="avatar"
                style={{width:'100px', height:'100px', borderRadius:'50%'}}
                   
            /> 
              
            <div className="details">
              <div className='detailItem'>
                <span className='itemKey'>Applicant ID:</span>
                <span className='itemValue'>{userData.applicantID}</span>
              </div>
              <div className='detailItem'>
                <span className='itemKey'>Date of Birth:</span>
                <span className='itemValue'>{userData.dob}</span>
              </div>
              <div className='detailItem'>
                <span className='itemKey'>Address:</span>
                <span className='itemValue'>{userData.address}</span>
              </div>
              <div className='detailItem'>
                <span className='itemKey'>Registered Since:</span>
                <span className='itemValue'>{userData.date}</span>
              </div>
            
            </div>

            <div className="details">
              
              <div className='detailItem'>
                <span className='itemKey'>Email:</span>
                <span className='itemValue'>{userData.email}</span>
              </div>
              <div className='detailItem'>
                <span className='itemKey'>Phone:</span>
                <span className='itemValue'>{userData.phoneNumber}</span>
              </div>
              {/* <div className='detailItem'>
                <span className='itemKey'>Address:</span>
                <span className='itemValue'>{userData.address}</span>
              </div> */}
            
            </div>

            </div>
          </div>
          <div>
            <Chart 
              aspect={3/1}
              title = "Applicant visa request last 6 months"
            />
          </div>
        </div>
        <div className="bottom">
        <div className='listTitle'>Applicant Visa Document Application History</div>
          <DataTableUserApplication
                applicantId = {applicantId}
          />
        </div>
      </div>
    </div>
  )
}

export default Single