import React, { useContext} from 'react'
import './sidebar.scss'
import Dashboard from '@mui/icons-material/Dashboard';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import StoreIcon from '@mui/icons-material/Store';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
// import BarChartIcon from '@mui/icons-material/BarChart';
import FlightIcon from '@mui/icons-material/Flight';
// import ListIcon from '@mui/icons-material/List';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ContactsIcon from '@mui/icons-material/Contacts';
import PaidIcon from '@mui/icons-material/Paid';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import ForumIcon from '@mui/icons-material/Forum';
import AppsIcon from '@mui/icons-material/Apps';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyIcon from '@mui/icons-material/Money';
import CampaignIcon from '@mui/icons-material/Campaign';
// import PsychologyIcon from '@mui/icons-material/Psychology';
// import SettingsIcon from '@mui/icons-material/Settings';
// import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LogoutIcon from '@mui/icons-material/Logout';
import { NavLink, Link } from 'react-router-dom'
import { DarkModeContext } from '../../context/darkModeContext';
import {signOut } from "firebase/auth";
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';


const Sidebar = () => {

  const { dispatch : setDarkMode } = useContext(DarkModeContext)

  const values = [
    { id : 1, text : "Dashboard", link: "/", iconName : Dashboard, heading:"LIST"},
    { id : 2, text : "Applicants", link: "/applicants", iconName : PeopleOutlineIcon, heading:""},
    { id : 3, text : "Visa Applications", link: "/applications", iconName : FlightIcon, heading:""},
    { id : 4, text : "Application History", link: "/applicationHistory", iconName : AppsIcon, heading:""},
    { id : 5, text : "Payments", link: "/payments", iconName : PaidIcon, heading:"USEFUL"},
    // { id : 9, text : "Calls", link: "/calls", iconName : ReviewsIcon, heading:""},
    // { id : 10, text : "Coupon", link: "/coupon", iconName : DeliveryDiningIcon, heading:""},
    { id : 6, text : "Settings", link: "/settings", iconName : SettingsIcon, heading:"MOBILE APP"},
    { id : 7, text : "Dollar Charges", link: "/charges", iconName : AttachMoneyIcon, heading:""},
    { id : 8, text : "Naira Charges", link: "/nairacharges", iconName : MoneyIcon, heading:""},
    { id : 9, text : "Messages", link: "/messages", iconName : ForumIcon, heading:""}, 
    { id : 10, text : "Announcement", link: "/announcement", iconName : CampaignIcon, heading:""},
    { id : 11, text : "Chats", link: "/chats", iconName : ChatIcon, heading:""},
    { id : 12, text : "Reviews", link: "/reviews", iconName : ReviewsIcon, heading:"WEBSITE"},
    // { id : 11, text : "Notifications", link: "/notifications", iconName : NotificationsNoneIcon, heading:"WEBSITE"},
    // { id : 12, text : "Others", link: "/", iconName : NotificationsNoneIcon, heading:""},
    
    // { id : 14, text : "Home Page", link: "/notifications", iconName : NotificationsNoneIcon, heading:""},
    // { id : 15, text : "About Page", link: "/reviews", iconName : ReviewsIcon, heading:""},
    // { id : 16, text : "Destination Page", link: "/chats", iconName : NotificationsNoneIcon, heading:""},
    // { id : 17, text : "Gallery Page", link: "/messages", iconName : NotificationsNoneIcon, heading:""},
    { id : 13, text : "Contact us", link: "/contacts", iconName : ContactsIcon, heading:""},
  ]

  const navigate = useNavigate()

  const { dispatch : setLogout} = useContext(AuthContext)

  // const { isActive, setIsActive } = useState(null)

 

  const logOut = () =>{
    signOut(auth).then(() => {

      setLogout({ type: 'LOGOUT' });
      localStorage.removeItem('currentUser');
      navigate('/login')
    }).catch((error) => {
      console.log(error)
    });
  }

  return (
    <div className='sidebar'>

      <div className='top-sidebar'>
          <div className="top">
            <Link to="/" style={{textDecoration:'none'}}>
                <span className='logo'>VISADOC ADMIN</span>
            </Link>
          </div>
          <hr />
          <div className="center">
            <ul>
              <p className='title'>MAIN</p>
              {values.map((value) => (
                  <div key = {value.id}>
                   
                   <li>
                      <NavLink to={value.link} style={({ isActive }) => ({
                        backgroundColor: isActive &&  "#ece8ff",
                        textDecoration:"none",
                        display:"flex",
                        width:"100%",
                        height:"100%",
                        padding:"5px",
                      })}>
                      <value.iconName className='icon'/>
                      <span>{value.text}</span>
                      </NavLink>
                      
                    </li>
                    {value.heading !== "" && <p className='title'>{value.heading}</p>}
                  </div>
                
              ))}
          
              
            </ul>
          </div>
          <p className="background">Background</p>
          <div className="bottom">
            <div className="colorOption" onClick={() => setDarkMode({type:"LIGHT"})}></div>
            <div className="colorOption" onClick={() => setDarkMode({type:"DARK"})}></div>
          </div> 
      </div>
      <div className='bottom-sidebar'>
            <LogoutIcon className='logout-icon'/>
            <span onClick={logOut}>Logout</span>
      </div>
    </div>
  )
}

export default Sidebar