import React from 'react'
import './applicationTableDocumentsInfo.scss' 
// import { Document, Page } from 'react-pdf';


const  ApplicationTableDocumentsInfo = ({ applicationData}) => {

  


        const downloadImage = (url) =>{

        
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.download = "downloaded-file";
              document.body.appendChild(link);
      
              link.click();
      
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("Error fetching the file:", error);
            });
       

        }



    // getDownloadURL(ref(storage, link))
    //     .then((url) => {
    //         // `url` is the download URL for 'images/stars.jpg'

    //         // This can be downloaded directly:
    //         const xhr = new XMLHttpRequest();
    //         xhr.responseType = 'blob';
    //         xhr.onload = (event) => {
    //         const blob = xhr.response;
    //         };
    //         xhr.open('GET', url);
    //         xhr.send();

    //         // Or inserted into an <img> element
    //         // const img = document.getElementById('myimg');
    //         // img.setAttribute('src', url);
    //     })
    //     .catch((error) => {
    //         // Handle any errors
    // });

    // getBlob(ref(storage, link))
    //     .then((blob) => {
    //         const href = URL.createObjectURL(blob)
    //         const a = Object.assign(document.createElement('a'), {
    //             href,
    //             style: 'display:none',
    //             download: link // This is where you set the name of the file you're about to download
    //         })
    //         a.click()

    //         URL.revokeObjectURL(href)
    //         a.remove()
    //     }).catch((error)=>{
    //         console.error(error)
    //     })

                                
 

  return (
            
    <div className=''>

        
    <div className='item'>
        <div className="details">

        <h4 style={{fontSize:18}}>Available Documents</h4>

            {
                applicationData.internationalPassport === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                    <div style={{display:'flex', flexDirection:'row', gap:20}}>
                        <span style={{fontWeight:'bold', color:'#00000088'}}>International Passport:</span>
                        <span className=''>{applicationData.internationalPassport}</span>
                    </div>
                    
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:40}}>
                    <a href={applicationData.internationalPassportImage} download>View</a>
                    </div>

                </div>
                :

                ""
            }  

         

                 {
                applicationData.passportPhotograph === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                    <div style={{display:'flex', flexDirection:'row', gap:20}}>
                        <span style={{fontWeight:'bold', color:'#00000088'}}>Passport Photograph:</span>
                        <span className='itemValue'>{applicationData.passportPhotograph}</span>
                    </div>
                    

                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.passportPhotographImage} download>View</a>


                    </div>
                  
                   
                </div>
                :

                ""
            }     

       

            {
                applicationData.birthCertificate  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:'bold', color:'#00000088'}}>Birth Certificate:</span>
                    <span className='itemValue'>{applicationData.birthCertificate }</span>
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.birthCertificateImage } download>View</a>

                    </div>
                  
                   
                </div>
                :

                ""
            }  

             

            {
                applicationData.marriageCertificate  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:'bold', color:'#00000088'}}>Marriage Certificate:</span>
                    <span className='itemValue'>{applicationData.marriageCertificate}</span>
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.marriageCertificateImage} download>View</a>

                       
                    </div>
                  
                   
                </div>
                :

                ""
            }  


           

            {
                applicationData.statementOfAccount  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:'bold', color:'#00000088'}}>Statement of Account:</span>
                    <span className='itemValue'>{applicationData.statementOfAccount}</span>
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.statementOfAccountImage} download>View</a>


                    </div>
                  
                   
                </div>
                :

                ""
            }  


            


            {
                applicationData.companyIntroduction  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:'bold', color:'#00000088'}}>Company Introduction Letter:</span>
                    <span className='itemValue'>{applicationData.companyIntroduction}</span>
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.companyIntroductionImage} download>View</a>

                       
                    </div>
                  
                   
                </div>
                :

                ""
            }  

           


            {
                applicationData.selfIntroduction  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:'bold', color:'#00000088'}}>Self Introduction Letter:</span>
                    <span className='itemValue'>{applicationData.selfIntroduction}</span>
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.selfIntroductionImage} download>View</a>


                    </div>
                  
                   
                </div>
                :

                ""
            }  



            {
                applicationData.leaveApprovalLetter  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:'bold', color:'#00000088'}}>Leave Approval Letter:</span>
                    <span className='itemValue'>{applicationData.leaveApprovalLetter}</span>
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.leaveApprovalLetterImage} download>View</a>

                       
                    </div>
                  
                   
                </div>
                :

                ""
            }  

             

{
                applicationData.employmentLetter  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:'bold', color:'#00000088'}}>Employment Letter:</span>
                    <span className='itemValue'>{applicationData.employmentLetter}</span>
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    <a href={applicationData.employmentLetterImage} download>View</a>

                    </div>
                  
                   
                </div>
                :

                ""
            }  
            
          
        
        </div>

    </div>  

    <div className='item'>     

        <div className="details">

        <h4>Unavailable Documents</h4>


            {
                applicationData.internationalPassport === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>International Passport:</span>
                    <span className='itemValue'>{applicationData.internationalPassport}</span>
                </div>
                :

                ""
            }  

             {
                applicationData.passportPhotograph === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Passport Photograph:</span>
                    <span className='itemValue'>{applicationData.passportPhotograph}</span>
                </div>
                :

                ""
            }      

            {
                applicationData.birthCertificate === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Birth Certificate:</span>
                    <span className='itemValue'>{applicationData.birthCertificate}</span>
                </div>
                :

                ""
            }  

             {
                applicationData.marriageCertificate === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Marriage Certificate:</span>
                    <span className='itemValue'>{applicationData.marriageCertificate}</span>
                </div>
                :

                ""
            }  
              


              {
                applicationData.statementOfAccount === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Statement of Account:</span>
                    <span className='itemValue'>{applicationData.statementOfAccount}</span>
                </div>
                :

                ""
            }  

             {
                applicationData.companyIntroduction === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Company Intro Letter:</span>
                    <span className='itemValue'>{applicationData.companyIntroduction}</span>
                </div>
                :

                ""
            }      

            {
                applicationData.selfIntroduction === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Self Introduction Letter:</span>
                    <span className='itemValue'>{applicationData.selfIntroduction}</span>
                </div>
                :

                ""
            }  

             {
                applicationData.leaveApprovalLetter === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Leave Approval Letter:</span>
                    <span className='itemValue'>{applicationData.leaveApprovalLetter}</span>
                </div>
                :

                ""
            }  
                
                {
                applicationData.employmentLetter === "unavailable"
                ?
                <div className='detailItem'>
                    <span className='itemKey'>Employment Letter:</span>
                    <span className='itemValue'>{applicationData.employmentLetter}</span>
                </div>
                :

                ""
            }  
            
          
        
        </div>

    </div>
    </div>
   
  );
}

export default ApplicationTableDocumentsInfo