import React, { useEffect, useState } from 'react'
import './dataTableContacts.scss'
import { DataGrid } from '@mui/x-data-grid';
import { announcementColumns } from '../../data/dataSource';
import { Link } from 'react-router-dom';
import { collection, getDocs,  deleteDoc, doc, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from '../../firebase'
import ConfirmBox from '../ConfirmBox';


const DataTable = () => {

  const [ data, setData ] = useState([])

  const [open, setOpen] = useState(false);

  const [deleteId, setDelete] = useState("");

  const [ isLoading, setIsLoading ] = useState(true)


  const Spinner = () => {
    return(
      <div className='spinner-container'>
          <div className='loading-spinner'></div>
      </div>
    )
  }


  useEffect(() => {

//LISTEN REAL TIME

    const collectionRef = collection(db, "announcement")
    const queryAnnouncements = query(collectionRef, orderBy("timeStamp", "desc"))

    const unsub = onSnapshot(queryAnnouncements, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data(), timeStamp: doc.data().timeStamp.toDate()})
      })
      setData(list)
      setIsLoading(false)
    }, 
      (error) => {
        console.log(error)
      }
    );

    return () => {
      unsub();
    }

//END LISTEN REAL TIME    

  },[])

  console.log(data)

  function openDelete(id) {
    setOpen(true);
    setDelete(id);
  }



  const handleDelete = async() => {

    
      await deleteDoc(doc(db, "announcement", deleteId));
      setData(data.filter((item) => item.id !== deleteId))

      setOpen(false)
    
  }

  const actionColumn = [

    { field: 'action', headerName: 'Action', width: 130, 
    
            renderCell:(params) => {
                return(
                    <div className='cellAction'>
                       <Link to={`/announcement/${params.row.id}`} style={{textDecoration:"none"}}>
                          <div className="viewbutton">View</div>
                       </Link>

                       <Link
                            onClick={() => openDelete(params.row.id)}
                            to={"#"}
                            className="deletebutton"
                          >
                            Delete
                       </Link>
                       
                    </div>
                )
            }
    }
      
  ]  

  return (
    <>
    
      <div className="datatable">
        <div className='datatableTitle'>
          All Announcement
          <Link to="/announcement/new"  style={{textDecoration:"none"}} >
          <div className='link'>
            Add Announcement
          </div>
        </Link>
        </div>
        {isLoading 
        ? <Spinner/>
        :<DataGrid
            className="announcementTableGrid"
            rows={data}
            columns={announcementColumns.concat(actionColumn)}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection
          />
        }
      </div>

      <ConfirmBox
      open={open}
      closeDialog={() => setOpen(false)}
      // title={deleteData?.name}
      deleteFunction={handleDelete}
      />
    </>

  )
}

export default DataTable