import React, { useEffect, useState } from 'react'
import './widget.scss'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'; 
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FlightIcon from '@mui/icons-material/Flight';
import { collection, where, query, getDocs, onSnapshot } from 'firebase/firestore'
import { sum, getAggregateFromServer, } from "@firebase/firestore";
import { db } from '../../firebase'
import { Link } from "react-router-dom";



const Widget= ({type}) => {

  const [diff, setDiff] = useState(null);
  const [applicant, setApplicant] = useState(0)
  const [application, setApplication] = useState(0)
  const [datas, setDatas ] = useState([])
  const [total, setTotal] = useState(0)
  const [nairaTotal, setNairaTotal] = useState(0)
  const [ncurrency, setNCurrency] = useState("")
  const [currency, setCurrency] = useState("")

  
  let data;

  switch(type){
    case "applicants":
      data={
        title:"TOTAL APPLICANTS",
        isMoney:false,
        link:"see all applicants",
        page:"/applicants",
        currency: "",
        amount: applicant,
        icon:<
          PersonOutlineOutlinedIcon className="icon" 
          style={{
          color:"crimson",
          backgroundColor: "rgba(255, 0, 0, 0.2)"
        }}/>,
      }
      break;
      case "applications":
        data={
          title:"TOTAL VISA APPLICATIONS",
          isMoney:false,
          link:"view all applications",
          page:"/applications",
          currency: "",
          amount: application,
          icon:<
          FlightIcon className="icon"
            style={{
              color:"goldenrod",
              backgroundColor: "rgba(218, 165, 32, 0.2)"
            }}
            />,
        }
        break;

      case "earning":
        data={
          title:"DOLLAR EARNINGS",
          isMoney:true,
          link:"view net earnings",
          page:"/earnings",
          currency: "$",
          amount: total ,
          icon:<MonetizationOnOutlinedIcon className="icon"
          style={{
            color:"green",
            backgroundColor: "rgba(0, 128, 0, 0.2)"
          }}/>,
        }
        break;

        case "naira":
        data={
          title:"NAIRA EARNINGS",
          isMoney:true,
          link:"view net earnings",
          page:"/earnings",
          currency: "N",
          amount: nairaTotal,
          icon:<AccountBalanceWalletOutlinedIcon className="icon"
          style={{
            color:"blue",
            backgroundColor: "rgba(0, 128, 0, 0.2)"
          }}/>,
        }
        break;
        
      // case "balance":
      //   data={
      //     title:"BALANCE",
      //     isMoney:true,
      //     link:"see details",
      //     page:"/balance",
      //     amount: total,
      //     icon:<AccountBalanceWalletOutlinedIcon className="icon"
      //     style={{
      //       color:"purple",
      //       backgroundColor: "rgba(128, 0, 128, 0.2)"
      //     }}
      //     />,
      //   }
      //   break;
      default:
      break
  }


  useEffect(() => {

    //LISTEN REAL TIME
    
        const collectionRef = collection(db, "visa")
    
        const unsub = onSnapshot(collectionRef, (snapShot) => {
          let list = [];
          // let total = 0
          
          snapShot.docs.forEach((doc) => {
            list.push(doc.data())
            // list.push({price:doc.data().price})
          })
          setDatas(list)
          // const earning = list.reduce((tot, currentValue) => tot = tot + currentValue.price,0);
          // setTotal(earning)
          }, 
            (error) => {
              console.log(error)
            }
          );
      
          return () => {
            unsub();
          }
      
    //END LISTEN REAL TIME    
    
      },[])

      console.log('Data info: ',datas)

    

    // console.log('earning:', earning);

    // console.log(datas);
    // console.log(total);

    // const users = [{ name: 'Tom', age: 21}, { name: 'Mike', age: 23}, { name: 'Anna', age: 54}]

    // const getAverageAge = (datas) => {
    //   let sum = 0
    //   for (let i = 0; i < datas.length; i++) {
    //     sum += datas[i].price
    //   }
    //   return sum
      
    // }
  
    //   getAverageAge(datas) 



        useEffect(() => {

    //LISTEN REAL TIME
    
        const collectionRef = collection(db, "visa")

        const r = query(collectionRef, where("constant", "==", 'active'), where("currency", "==", 'USD'));


        const nr = query(collectionRef, where("constant", "==", 'active'), where("currency", "==", 'NGN'));


        // const r = query(collectionRef, where('status', 'in', ['RECEIVED', 'PROCESSING', 'DELIVERING', 'DELIVERED', 'APPROVED']));
    
        const unsub = onSnapshot(r, (snapShot) => {
          let list = [];
          // let currency = []
          // let total = 0
          
          snapShot.docs.forEach((doc) => {
            list.push({total:doc.data().total})
            // currency.push({currency:doc.data().currency})
          })


          // setDatas(list)
          const earning = list.reduce((tot, currentValue) => tot = tot + currentValue.total,0);
          setTotal(earning)
          // setCurrency(currency)
          }, 
            (error) => {
              console.log(error)
            }
          );

          const unsubn = onSnapshot(nr, (snapShot) => {
            let nlist = [];
            // let ncurrency = []
            // let total = 0
            
            snapShot.docs.forEach((doc) => {
              nlist.push({total:doc.data().total})
              // ncurrency.push({currency:doc.data().currency})
            })
  
  
            // setDatas(list)
            const nearning = nlist.reduce((tot, currentValue) => tot = tot + currentValue.total,0);
            setNairaTotal(nearning)
            // setNCurrency(ncurrency)
            }, 
              (error) => {
                console.log(error)
              }
            );
      
          return () => {
            unsub();
            unsubn();
          }
      
    //END LISTEN REAL TIME    
    
      },[])

    
  console.log('total: ', total)
  // console.log('currency: ', currency)
  // console.log('naira currency: ', ncurrency)



  useEffect(() => {
    const fetchData = async() => {
      const today = new Date();
      const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1))
      const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2))
      console.log('last month:', lastMonth)
      console.log('previous month:', prevMonth)
      console.log('today:', today)

      const lastMonthQuery = query(
        collection(db, "visa"), 
        where("timeStamp", "<=", today),
        where("timeStamp", ">", lastMonth)
      );
      const prevMonthQuery = query(
        collection(db, "visa"), 
        where("timeStamp", "<=", lastMonth),
        where("timeStamp", ">", prevMonth)
      );

      

      const travellersQuery = collection(db, "travellers")
      const allTravellers = await getDocs(travellersQuery)
      setApplicant(allTravellers.docs.length)

      const visaQuery = collection(db, "visa")
      const allVisa = await getDocs(visaQuery)
      setApplication(allVisa.docs.length)

 
      const lastMonthData = await getDocs(lastMonthQuery)
      const prevMonthData = await getDocs(prevMonthQuery)

    
      setDiff((lastMonthData.docs.length - prevMonthData.docs.length) / (prevMonthData.docs.length) * 100)

      // let timestamp = '1452488445471';
      // let newDate = new Date(timestamp * 1000)
      // let thisDay = new Date(timeStamp)
      // let thisDate = thisDay.toLocaleString()
      // let Hours = thisDay.getHours()
      // let Minutes = thisDay.getMinutes()
      // let HourComplete = Hours + ':' + Minutes
      // let formatedTime = HourComplete
      // console.log(thisDay)
      // console.log(formatedTime)
      // console.log(thisDate)
      // console.log(thisDay)
      // console.log(today)
        
    };

    fetchData();

  }, [])

   
  console.log("applicants:", applicant)
  console.log("application:", application)

  return (
        <div className='widget'>
          <div className="left">
            <span className="title">{data.title}</span>
            <span className="counter" to>
              {data.isMoney && data.currency}{data.amount}
            </span>
            <Link to={data.page} className='page'>
            <span className="link">{data.link}</span>
            </Link>
          </div>
          <div className="right">
            <div className="percentage positive">
              <KeyboardArrowUpOutlinedIcon/>
              {/* {diff}% */}
            </div>
            {data.icon}
          </div>
        </div>
      )

//   return (
//     <>
//          {

// info.map(item => (

//   <div key={item.id} className='widget'>
//   <div className="left">
//     <span className="title"></span>
//     <span className="counter">
//       {data.isMoney && '$'}{data.amount} 
//       {data.amount}
//     </span>
//     <Link to={data.page} className='page'>
//     <span className="link"></span>
//     </Link>
//   </div>
//   <div className="right">
//     <div className="percentage positive">
//       <KeyboardArrowUpOutlinedIcon/>
//       {/* {diff}% */}
//     </div>
//     {}
//   </div>
// </div>

// ))

// }
//     </>

   
//     // <div className='widget'>
//     //   <div className="left">
//     //     <span className="title"></span>
//     //     <span className="counter">
//     //       {/* {data.isMoney && 'N'}{data.amount} */}
//     //       {data.amount}
//     //     </span>
//     //     <Link to={data.page} className='page'>
//     //     <span className="link"></span>
//     //     </Link>
//     //   </div>
//     //   <div className="right">
//     //     <div className="percentage positive">
//     //       <KeyboardArrowUpOutlinedIcon/>
//     //       {/* {diff}% */}
//     //     </div>
//     //     {}
//     //   </div>
//     // </div>
//   )
}

export default Widget


