import React, { useEffect, useState } from 'react';
// import './dataTableDishes.scss';
import { DataGrid } from '@mui/x-data-grid';
import { nairaChargesColumns } from '../../data/dataSource';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot, orderBy,query } from "firebase/firestore";
import { db } from '../../firebase'



const DataTable = () => {

  const [ data, setData ] = useState([])

  const [ isLoading, setIsLoading ] = useState(true)


  const Spinner = () => {
    return(
      <div className='spinner-container'>
          <div className='loading-spinner'></div>
      </div>
    )
  }

  useEffect(() => {
   
    
//LISTEN REAL TIME

    const collectionRef = collection(db, "nairacharges")
    const queryNairaCharges = query(collectionRef, orderBy("timeStamp", "desc"))

    const unsub = onSnapshot(queryNairaCharges, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data(), timeStamp: doc.data().timeStamp.toDate()})
      })
      setData(list)
      setIsLoading(false)
    }, 
      (error) => {
        console.log(error)
      }
    );

    return () => {
      unsub();
    }

//END LISTEN REAL TIME    

  },[])

  console.log(data)

  const handleDelete = async (id) => {

    try{

      await deleteDoc(doc(db, "nairacharges", id));
      setData(data.filter((item) => item.id !== id))

    }catch(err){

      console.log(err)

    }
   
  }

  const actionColumn = [

    { field: 'action', headerName: 'Action', width: 140, 
    
            renderCell:(params) => {
                return(
                    <div className='cellAction'>
                       {/* <Link to={`/nairacharges/${params.row.id}`} style={{textDecoration:"none"}}>
                          <div className="viewbutton">View</div>
                       </Link> */}
                        <Link to={`/nairacharges/edit/${params.row.id}`} style={{textDecoration:"none"}}>
                        <div className="viewbutton">Edit</div>
                     </Link>
                       
                       {/* <div className='deletebutton' onClick={() => handleDelete(params.row.id)}>Delete</div> */}
                    </div>
                )
            }
    }
      
  ]  

  return (
    <div className="datatable">
      <div className='datatableTitle'>
        All Naira Charges
        {/* <Link to="/nairacharges/new"  style={{textDecoration:"none"}} >
          <div className='link'>
            Add Naira Charges
          </div>
        </Link> */}
      </div>
      {isLoading ? <Spinner/> : 
        <DataGrid
            className="chargesTableGrid"
            rows={data}
            columns={nairaChargesColumns.concat(actionColumn)}
            pageSize={5}
            rowsPerPageOptions={[10]}
            checkboxSelection
        />
    }
    </div>
  )
}

export default DataTable