import React, { useEffect, useState } from 'react'
import './dataTableApplicationHistory.scss'
import { DataGrid } from '@mui/x-data-grid';
import { applicationHistoryColumns } from '../../data/dataSource';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, query, where, getDocs  } from "firebase/firestore";
import { db } from '../../firebase'
import ConfirmBox from '../ConfirmBox';



const DataTable = () => {

  const [ data, setData ] = useState([])

  const [open, setOpen] = useState(false);

  const [deleteId, setDelete] = useState("");

  const [ isLoading, setIsLoading ] = useState(true)


  const Spinner = () => {
    return(
      <div className='spinner-container'>
          <div className='loading-spinner'></div>
      </div>
    )
  }

//   useEffect(() => {
   
    
//LISTEN REAL TIME

    // const unsub = onSnapshot(collection(db, "order"), (snapShot) => {
    //   let list = [];
    //   snapShot.docs.forEach((doc) => {
    //     list.push({ id: doc.id, ...doc.data(), createdAt: new Date().getTime()})
        
    //   })
    //   setData(list)
    //   setIsLoading(false)

      // console.log("user created at:", data.createdAt.toDate());
    // }, 
    //   (error) => {
    //     console.log(error)
    //   }
    // );

    // return () => {
    //   unsub();
    // }

// //END LISTEN REAL TIME    

//   },[])

//   console.log(data)

  // const savedTime    = data.timeStamp;
  // const formatedDate = new Date(savedTime).toLocaleString(
  // "en-US",
  //   {
  //     month: "short",
  //     day: "2-digit",
  //     year: "numeric",
  //   }
  // );

  // console.log(data.timeStamp);


 

    const queryVisa = async() => {

        const q = query(collection(db, "visa"), where("status", "==", "APPROVED"));
  
        const querySnapshot = await getDocs(q);
    
        let list = [];

        querySnapshot.forEach((doc) => {
    
            list.push({ id: doc.id, ...doc.data(), timeStamp: doc.data().timeStamp.toDate()})

        })

        setData(list)
        setIsLoading(false)

    }
   
    useEffect(() => {
        queryVisa()
    }, [])



    function openDelete(id) {
      setOpen(true);
      setDelete(id);
    }
  
 
  const handleDelete = async () => {

    try{

      await deleteDoc(doc(db, "visa", deleteId));
      setData(data.filter((item) => item.id !== deleteId))

    }catch(err){

      console.log(err)

    }
   
  }

  const actionColumn = [

    { field: 'action', headerName: 'Action', width: 130, 
    
            renderCell:(params) => {

                return(
                    <div className='cellAction'>
                       <Link to={`/applicationHistory/${params.row.id}`} style={{textDecoration:"none"}}>
                          <div className="viewbutton">View</div>
                       </Link>
                       <Link
                            // onClick={() => openDelete(item)}
                            onClick={() => openDelete(params.row.id)}
                            to={"#"}
                            className="deletebutton"
                          >
                            Delete
                       </Link>
                    </div>
                )
            }
    }
      
  ]  

  return (
    <>
    <div className="datatable">
      <div className='datatableTitle'>
        Completed Visa Applications
      </div>
      {isLoading ? <Spinner/> : 
        <DataGrid
            className="applicationTableGrid"
            rows={data}
            columns={applicationHistoryColumns.concat(actionColumn)}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
        />
    }

      
    </div>
    <ConfirmBox
      open={open}
      closeDialog={() => setOpen(false)}
      // title={deleteData?.name}
      deleteFunction={handleDelete}
    />
    </>
  )
}

export default DataTable