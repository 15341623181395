import React, { useEffect, useState, useContext } from 'react'
import './single.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import Chart from '../../../components/chart/Chart'
import { doc, getDoc, getDocs, where, collection, query } from "firebase/firestore"
import { useHistory, useParams } from "react-router-dom"
import { db } from '../../../firebase'
import ApplicantTable from '../../../components/table/ApplicantTable'
import { AuthContext } from "../../../context/AuthContext";
import DataTableUserApplication from "../../../components/dataTable/DataTableUserApplication"
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Single = () => {

  const {contactId} = useParams()

  // const { currentUser } = useContext(AuthContext)


  const [ contactData, setContactData ] = useState("")


  const getContact = async() => {

      const docRef = doc(db, "contact", contactId);

      const docSnap = await getDoc(docRef);

    
      
      if (docSnap.exists()) {

        setContactData(docSnap.data())
        // console.log(userData)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
  }

  useEffect(()=>{
    getContact()
  }, [])


  return (
    <div className='single'>
    <Sidebar/>
    <div className='singleContainer'>
      <Navbar/>
      <div className="top">
      <div className='topTitle'>

        <div className='topLeft'></div>
          
          <div className='topRight'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/contacts"
            >
              Contact Us
            </Link>
            <Typography color="green">Contact Us Details</Typography>
          </Breadcrumbs>
          </div>
      </div>
       
      </div>

      <div className="bottom">
         
          
          <div className='item'>

          <h2 className='title'>Contact Us Details</h2>

          <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                <span className='itemKey'>Name:</span>
                <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                  <span className='itemValue'>{contactData.name}</span>
                </div>
          </div>

          <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                <span className='itemKey'>Email:</span>
                <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                  <span className='itemValue'>{contactData.email}</span>
                </div>
          </div>
          <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                <span className='itemKey'>Phone:</span>
                <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                  <span className='itemValue'>{contactData.telephone}</span>
                </div>
          </div>
          <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                <span className='itemKey'>Subject:</span>
                <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                  <span className='itemValue'>{contactData.subject}</span>
                </div>
          </div>
          <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                <span className='itemKey'>Message:</span>
                <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                  <span className='itemValue'>{contactData.message}</span>
                </div>
          </div>
         
          </div>
        </div>
  
    </div>
  </div>
  )
}

export default Single