
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

class RichText extends React.Component {

  handleEditorChange = (e) => {
    console.log(
      'Content was updated:',
      e.target.getContent()
    );
  }

  render() {
    return (

        <Editor
        apiKey='7512nbbkwns78rjn8an8t3gtfmgrtzlqypkpay960fe73vh5'
        init={{
          height: 500,
          menubar: false,
            
          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
          toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
          tinycomments_mode: 'embedded',
          tinycomments_author: 'Author name',
          mergetags_list: [
            { value: 'First.Name', title: 'First Name' },
            { value: 'Email', title: 'Email' },
          ],
          ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
        }}
        initialValue="Enter Content"
        onChange={this.handleEditorChange}
      />


    //   <Editor
    //     initialValue="<p>Wow ! ... It Works !!!</p>"
    //     init={{
    //       height: 500,
    //       menubar: false,
    //       plugins: [
    //         'advlist autolink lists link image',
    //         'charmap print preview anchor help',
    //         'searchreplace visualblocks code',
    //         'insertdatetime media table paste wordcount'
    //       ],
    //       toolbar:
    //         'undo redo | formatselect | bold italic | \
    //         alignleft aligncenter alignright | \
    //         bullist numlist outdent indent | help'
    //     }}
    //     onChange={this.handleEditorChange}
    //   />
    );
  }
}

export default RichText;