import React, { useContext, useState} from 'react'
import './login.scss'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom'
import {signOut } from "firebase/auth";


export const Login = () => {

  const [ error, setError ] = useState(false);
  const [ email, setEmail ] = useState("");
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [ password, setPassword ] = useState("");
  const [serverError, setServerError] = useState("")

  const navigate = useNavigate()

   const { dispatch, setLogout } = useContext(AuthContext)

  // const handleLogin = (e) => { 
    
  //     e.preventDefault();

  //     signInWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
        
  //        const user = userCredential.user;
  //        dispatch({type:"LOGIN", payload:user})

  //       navigate("/")
        
  //     })
  //     .catch((error) => {

  //       setError(true)

        
  //     });

  // };

  const logOut = () =>{

    signOut(auth).then(() => {

      setLogout({ type: 'LOGOUT' });

      localStorage.removeItem('currentUser');

      navigate('/login')

    }).catch((error) => {

      console.log(error)

    });

  }


  
  const handleLogin = (e) => {

    e.preventDefault();

    if(email === ""){
      setEmailError("Field is required")
    }

    if(password === ""){
      setPasswordError("Field is required")
    }

      // const firebase = getFirebase();

      try {

        signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
        
            const currentUser = userCredential.user;

            console.log('current user details: ', currentUser)

            currentUser.getIdTokenResult().then(idTokenResult => {

              console.log('My Token result: ',idTokenResult.claims)


              if (!!idTokenResult.claims.admin) {

                dispatch({type:"LOGIN", payload:currentUser})
  
                navigate("/")

                // Show admin UI.
                // showAdminUI();

              } else {

                console.log('is not admin');

                alert("Only admin can have access")

                logOut();
                // Show regular user UI.
                // showRegularUI();
              }

            // console.log(user);

            // user.admin = idTokenResult.claims.admin;

        })

        .catch((error) => {

          const errorCode = error.code;

          if(errorCode === 'auth/invalid-email'){
            setServerError('Invalid email')
          }

          if(errorCode === 'auth/invalid-login-credentials'){
            setServerError('Invalid Login Credentials')
          }

          console.log(error)
  
          setError(true)
        
                
        });
        
  
          // const currentUser = firebase.auth().currentUser;
          // currentUser.getIdTokenResult().then(idTokenResult => {
          //   console.log(currentUser);
          //   currentUser.superAdmin = idTokenResult.claims.superAdmin;
  
            // if (!currentUser.superAdmin) {
            //   console.log('is not admin');
            //   firebase.logout();
            // }
          });
  
      } catch (error) {

        const errorCode = error.code;

        if(errorCode === 'auth/invalid-email'){

          setServerError('Invalid Email')

        }

        if(errorCode === 'auth/invalid-login-credentials'){

          setServerError('Invalid Login Credentials')

        }

        console.log(error)

        setError(true)
        // console.log(error);
        // throw new SubmissionError({
        //   _error: 'Login failed'
        // });
      }
  };


    return (
    <div className='login'>
      
      <fieldset>
        <legend>VisaDoc</legend>

        <form onSubmit={handleLogin}>

        {/* {
            serverError && 

            <p className='emailError'>{serverError}</p>
          } */}

          <div className='Emailwrapper'>

          <input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)}/>
          {
            emailError && 

            <p className='emailError'>{emailError}</p>
          }

          </div>

         <div className='passwordWrapper'>

         <input type="password" placeholder='Password'  onChange={e => setPassword(e.target.value)}/>
          {
            passwordError && 

            <p className='passwordError'>{passwordError}</p>
          }

         </div>
         
          <Link to="/forgotPwd" style={{textDecoration:'none'}}>
            <div className='forgotPwd'>
                <div>Forgot Password</div>
            </div>
          </Link>
          <button type="submit">Login</button>
          {/* <span>{error && "Wrong email or password"}</span> */}
          {
            serverError && 

            <p className='emailError'>{serverError}</p>
          }
        </form>


      </fieldset>

    </div>
  )
}
