import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';


const firebaseConfig = {

  apiKey: "AIzaSyD9KtCCGm5nFJYu4WEJFXcR1G06M7l9foo",
  authDomain: "visadocflymate-82bea.firebaseapp.com",
  projectId: "visadocflymate-82bea",
  storageBucket: "visadocflymate-82bea.appspot.com",
  messagingSenderId: "475499251201",
  appId: "1:475499251201:web:6845fb55f6611bbbb0e200",
  measurementId: "G-83P38PMGXD"


};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const functions = getFunctions(app);