import React, { useEffect, useState } from 'react'
// import './dataTableApplicants.scss'
// import { DataGrid } from '@mui/x-data-grid';
// import { chatsColumns } from '../../data/dataSource';
// import { Link } from 'react-router-dom';
import { collection, getDocs,  deleteDoc, doc, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from '../../firebase';
// import swal from 'sweetalert';
// import ConfirmBox from '../ConfirmBox';

function Chats() {

    const [ datas, setDatas ] = useState([])

    const [open, setOpen] = useState(false);
  
    const [deleteId, setDelete] = useState("");
  
    const [ isLoading, setIsLoading ] = useState(true)


    useEffect(() => {

    const collectionRef = collection(db, "travellers")
    const queryCustomers = query(collectionRef, orderBy("timeStamp", "desc"))

    const unsub = onSnapshot(queryCustomers, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data(), timeStamp: doc.data().timeStamp.toDate()})
      })
      setDatas(list)
      setIsLoading(false)
    }, 
      (error) => {
        console.log(error)
      }
    );

    return () => {
      unsub();
    }
 

  },[])

  
  
    const Spinner = () => {
      return(
        <div className='spinner-container'>
            <div className='loading-spinner'></div>
        </div>
      )
    }


    
  

  return (

    <div>

<ul>
      {datas.map(item => (
        <li key={item}>{item.firstname} {item.firstname}</li>
      ))}
    </ul>

    </div>
  )
}

export default Chats