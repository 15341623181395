import React, {useState, useEffect} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './salesTable.scss'
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { getDoc, doc, collection, onSnapshot,  query, orderBy, where, limit } from 'firebase/firestore' 



const  SalesTable = () => {

  const [ rows, setRows ] = useState([])

  // useEffect(() => {
    
  //   //LISTEN REAL TIME
    
  //       onSnapshot(collection(db, "visa"), (snapShot) => {
  //         let list = [];
  //         snapShot.docs.forEach((doc) => {
  //           list.push({ id: doc.id, ...doc.data(), month: doc.data().timeStamp.toDate().toLocaleString() })
  //         })
  //         setRows(list)
          
  //       }, 
  //         (error) => {
  //           console.log(error)
  //         }
  //       ); 
     
  // },[])

  useEffect(() => {
   
    
    const collectionRef = collection(db, "visa")
    const queryApplication = query(collectionRef, where('status', '==', 'PAYMENT'))

    const unsub = onSnapshot(queryApplication, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push(doc.data())
        
      })

      setRows(list)
      // setIsLoading(false)

      // console.log("user created at:", data.createdAt.toDate());
    }, 
      (error) => {
        console.log(error)
      }
    );

    return () => {
      unsub();
    }

// //END LISTEN REAL TIME    

  },[])

  console.log('LIST: ', rows)

  

  
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="tableRow"> 
  

            {/* <TableCell className='tableCell'>Visa ID</TableCell> */}
            <TableCell className='tableCell'>Applicant</TableCell>
            {/* <TableCell className='tableCell'>Application Date</TableCell> */}
            <TableCell className='tableCell'>Fee</TableCell>
            <TableCell className='tableCell'>Where To</TableCell>
            <TableCell className='tableCell'>Status</TableCell>
            <TableCell className='tableCell'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}  className="tableRow">
                <TableCell className='tableCell'>{row.firstname} {row.surname}</TableCell>
                <TableCell className='tableCell'>{row.currency}{row.total}</TableCell>
                <TableCell className='tableCell'>{row.country}</TableCell>
                <TableCell className='tableCell'>CONFIRM {row.status}</TableCell>
                <TableCell className='tableCell'>
                {
                    <Link to={`/applications/${row.visaId}`} style={{textDecoration:"none"}}>
                    <div style={{
                      color: "green",
                      borderRadius: 5,
                      paddingTop:10,
                      paddingBottom:10,
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign:"center",
                      backgroundColor: "lightgreen",
                    }}>
                      View
                    </div>
                 </Link>


                }
                
                </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SalesTable