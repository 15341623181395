import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import './applicationTableEmploymentInfo.scss' 


const  ApplicationTableEducationInfo = ({ applicationData}) => {

  return (
            
    <div className=''>

        <div style={{display:'flex', flexDirection:'column', gap:20}}>

              
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Education:</span>
                <span className='itemValue'>{applicationData.education}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>School Attended:</span>
                <span className='itemValue'>{applicationData.schoolAttended}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Admission Date:</span>
                <span className='itemValue'>{applicationData.dateOfAdmission}</span>
            </div>

            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Graduation Date:</span>
                <span className='itemValue'>{applicationData.dateOfGraduation}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:20}}>
                <span style={{fontWeight:600, color:'gray'}}>Course Studied:</span>
                <span className='itemValue'>{applicationData.course}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:20}}>
                <span style={{fontWeight:600, color:'gray'}}>School Address:</span>
                <span className='itemValue'>{applicationData.schoolAddress}</span>
            </div>
        
        </div>
    </div>
   
  );
}

export default ApplicationTableEducationInfo