
export const applicantsColumns = [
    { 
        field: 'photo', headerName: 'Photo', width:70,
        renderCell:(params) => {
            return(
                <div className="cellwithphoto">
                    <img src={params.row.img?params.row.img:  "../images/user.png"} alt="avatar" className="cellphoto"/>
               
                </div>
            )
        }
    },
    { field: 'applicantID', headerName: 'Applicant ID', width: 130 },
    { field: 'firstname', headerName: 'Firstname', width: 100 },
    { field: 'surname', headerName: 'Lastname', width: 100 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 80 },
    { field: 'timeStamp', headerName: 'Registered Date', width: 150,
    },
    { field: 'status', headerName: 'Status', width: 80,
        renderCell:(params) => {
            return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        },
    },
]

export const applicationsColumns = [
    { field: 'biometricID', headerName: 'Biometric ID', width: 120 },
    { field: 'firstname', headerName: 'Firstname', width: 140 },
    { field: 'surname', headerName: 'Lastname', width: 140 },
    { field: 'country', headerName: 'Destination ', width: 100 },
    { field: 'timeStamp', headerName: 'Application Date', width: 210,

    },
    { field: 'status', headerName: 'Visa Status', width: 120,
        renderCell:(params) => {
            return (
                
                    <>
                        {params.row.status === 'APPLYING' && <div style={{ color:'red', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>APPLYING</div>} 
                        {params.row.status === 'PAYMENT' && <div style={{ color:'lightgreen', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>PAYMENT</div>} 
                        {params.row.status === 'RECEIVED' && <div style={{ color:'green', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>RECEIVED</div>} 
                        {params.row.status === 'PROCESSING' && <div  style={{ color:'orange', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>PROCESSING</div>} 
                        {params.row.status === 'DELIVERING' && <div  style={{color:'blue', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>DELIVERYING</div>} 
                        {params.row.status === 'DELIVERED' && <div style={{color:'black', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>DELIVERED</div>} 
                        {params.row.status === 'APPROVED' && <div style={{ color:'gray', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>APPROVED</div>} 
                        {params.row.status === 'DECLINED' && <div style={{color:'red', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>DECLINED</div>} 
                    </>
                   )
        },
    },
]


export const userApplicationsColumns = [
    // { field: 'visaId', headerName: 'Visa ID', width: 120 },
    { field: 'firstname', headerName: 'Firstname', width: 150 },
    { field: 'surname', headerName: 'Lastname', width: 150 },
    { field: 'country', headerName: 'Destination', width: 100 },
    { field: 'total', headerName: 'Processing Fee ', width: 150 ,
        renderCell:(params) => {
            return (
                    <>
                        {params.row.currency} {params.row.total}
                    </>
                )
        },
    },    
    { field: 'timeStamp', headerName: 'Application Date', width: 150 },
    { field: 'status', headerName: 'Status', width: 150,
        renderCell:(params) => {
            return (
                    <>
                        {params.row.status === 'APPLYING' && <div style={{ color:'red', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>APPLYING</div>} 
                        {params.row.status === 'PAYMENT' && <div style={{ color:'yellow', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>PAYMENT</div>} 
                        {params.row.status === 'RECEIVED' && <div style={{ color:'green', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>RECEIVED</div>} 
                        {params.row.status === 'PROCESSING' && <div  style={{ color:'orange', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>PROCESSING</div>} 
                        {params.row.status === 'DELIVERING' && <div  style={{color:'blue', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>DELIVERYING</div>} 
                        {params.row.status === 'DELIVERED' && <div style={{color:'black', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}> DELIVERED</div>} 
                        {params.row.status === 'APPROVED' && <div style={{ color:'black', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>COMPLETED </div>} 
                        {params.row.status === 'DECLINED' && <div style={{color:'red', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>DECLINED </div>} 
                    </>
                   )
        },
    },
]

export const chargesColumns = [

    { field: 'premiumServiceCharge', headerName: 'Premium Service Charge', width: 250 },
    { field: 'regularServiceCharge', headerName: 'Regular Service Charge', width: 250 },
    { field: 'visaFee', headerName: 'Visa Fee', width: 150 },
    { field: 'timeStamp', headerName: 'Date', width: 120, },
  
]

export const nairaChargesColumns = [

    { field: 'premiumServiceCharge', headerName: 'Premium Service Charge', width: 250 },
    { field: 'regularServiceCharge', headerName: 'Regular Service Charge', width: 250 },
    { field: 'visaFee', headerName: 'Visa Fee', width: 150 },
    { field: 'timeStamp', headerName: 'Date', width: 120, },
  
]

export const applicationHistoryColumns = [
    { field: 'applicantID', headerName: 'Applicant ID', width: 100 },
  
    { field: 'firstname', headerName: 'Firstname', width: 140 },
    { field: 'surname', headerName: 'Lastname', width: 140 },
    { field: 'country', headerName: 'Destination ', width: 100 },
    { field: 'timeStamp', headerName: 'Application Date', width: 210,},
    { field: 'Visa Status', headerName: 'Status', width: 200,
        renderCell:(params) => {
            return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        },
    },
]

export const paymentColumns = [
    { field: 'paymentID', headerName: 'Payment ID', width: 150 },
    { field: 'total', headerName: 'Processing Fee ', width: 150 ,
        renderCell:(params) => {
            return (
                    <>
                        {params.row.currency} {params.row.total}
                    </>
                )
        },
    },
    { field: 'firstname', headerName: 'Firstname', width: 140 },
    { field: 'surname', headerName: 'Lastname', width: 140 },
    { field: 'country', headerName: 'Destination ', width: 100 },
    { field: 'timeStamp', headerName: 'Application Date', width: 210,},
    
    { field: 'Visa Status', headerName: 'Status', width: 150,
        renderCell:(params) => {
            return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        },
    },
]

export const messagesColumns = [

    { field: 'firstname', headerName: 'Firstname', width: 140 },
    { field: 'surname', headerName: 'Lastname', width: 140 },
    { field: 'message', headerName: 'Message', width: 220 },
    { field: 'status', headerName: 'Status', width: 140 },
    { field: 'timeStamp', headerName: 'Message Date', width: 210,

   
    },
   
]


export const chatsColumns = [
    // { 
    //     field: 'firstname', headerName: 'Firstname', width:140,
    //     renderCell:(params) => {
    //         return(
    //             <div className="cellwithphoto">
    //                 <img src={params.row.img?params.row.img:  "../images/user.png"} alt="avatar" className="cellphoto"/>
               
    //             </div>
    //         )
    //     }
    // },
    { field: 'firstname', headerName: 'Firstname', width: 130 },
    // { field: 'firstname', headerName: 'Firstname', width: 100 },
    // { field: 'surname', headerName: 'Lastname', width: 100 },
    // { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    // { field: 'gender', headerName: 'Gender', width: 80 },
    // { field: 'timeStamp', headerName: 'Registered Date', width: 150,
    // },
    // { field: 'status', headerName: 'Status', width: 80,
    //     renderCell:(params) => {
    //         return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
    //     },
    // },
]


export const riderColumns = [
    
        { 
            field: 'photo', headerName: 'photo', width:60,
            renderCell:(params) => {
                return(
                    <div className="cellwithphoto">
                        <img src={params.row.img} alt="avatar" className="cellphoto" />
                        
                    </div>
                )
            }
        },
        { field: 'fullname', headerName: 'Fullname', width: 120 },
        { field: 'email', headerName: 'email', width: 230 },
        { field: 'address', headerName: 'address', width: 170 },
        { field: 'telephone', headerName: 'telephone', width: 150 },
        { field: 'timeStamp', headerName: 'Registered Date', width: 160 },
        // { field: 'status', headerName: 'status', width: 100,
        //     renderCell:(params) => {
        //         return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        //     },
        // },
       
    
]


export const ridesColumns = [
    { field: 'transacton', headerName: 'Order ID', width: 120 },
    // { 
    //     field: 'photo', headerName: 'Photo', width:100,
    //     renderCell:(params) => {
    //         return(
    //             <div className="cellwithphoto">
    //                 <img src={params.row.img} alt="avatar" className="cellphoto"/>
                    
    //             </div>
    //         )
    //     }
    // },
    // { field: 'transaction', headerName: 'Ordered Item', width: 150 },
    // { field: 'title', headerName: 'Food Ordered', width: 170 },
    { field: 'fullname', headerName: 'Rider Name', width: 200 },
    { field: 'price', headerName: 'Price (N)', width: 100 },
    { field: 'quantity', headerName: 'quantity', width: 80 },
    { field: 'timeStamp', headerName: 'Ride Date', width: 210,

        // renderCell:(params) => {
        //     return(
        //         <div>{params.row.timeStamp}</div>
        //     )
        // }
    },
    { field: 'status', headerName: 'Status', width: 200,
        renderCell:(params) => {
            return (
                    <>
                        {params.row.status === 'RECEIVED_ORDER' && <div style={{ color:'green', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>ORDER RECEIVED</div>} 
                        {params.row.status === 'PROCESSING_ORDER' && <div  style={{ color:'orange', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>PROCESSING ORDER</div>} 
                        {params.row.status === 'DELIVERING_ORDER' && <div  style={{color:'blue', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>DELIVERYING ORDER</div>} 
                        {params.row.status === 'DELIVERED_ORDER' && <div style={{color:'black', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>ORDER DELIVERED</div>} 
                        {params.row.status === 'ORDER_COMPLETED' && <div style={{ color:'gray', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>COMPLETED ORDER</div>} 
                        {params.row.status === 'ORDER_DECLINED' && <div style={{color:'red', padding:'10px', alignItems:'center', borderRadius:'5px', width:'100%' }}>DECLINED ORDER</div>} 
                    </>
                   )
        },
    },
]



export const rideHistoryColumns = [
    { field: 'id', headerName: 'Order ID', width: 80 },
    // { 
    //     field: 'photo', headerName: 'Photo', width:100,
    //     renderCell:(params) => {
    //         return(
    //             <div className="cellwithphoto">
    //                 <img src={params.row.img} alt="avatar" className="cellphoto"/>
                    
    //             </div>
    //         )
    //     }
    // },
    { field: 'transaction', headerName: 'Ordered Item', width: 150 },
    // { field: 'title', headerName: 'Food Ordered', width: 170 },
    { field: 'fullname', headerName: 'Customer Name', width: 150 },
    { field: 'price', headerName: 'Price (N)', width: 100 },
    { field: 'quantity', headerName: 'quantity', width: 80 },
    { field: 'timeStamp', headerName: 'Ordered Date', width: 200 },
    { field: 'status', headerName: 'Status', width: 200,
        renderCell:(params) => {
            return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        },
    },
]

export const driversColumns = [
    { field: 'id', headerName: 'Driver ID', width: 80 },
    { 
        field: 'photo', headerName: 'Photo', width:100,
        renderCell:(params) => {
            return(
                <div className="cellwithphoto">
                    <img src={params.row.img} alt="avatar" className="cellphoto"/>
                    
                </div>
            )
        }
    },
    { field: 'firstname', headerName: 'Firstname', width: 100 },
    { field: 'Lastname', headerName: 'Lastname', width: 100 },
    { field: 'telephone', headerName: 'Phone Number', width: 150 },
    { field: 'platenumber', headerName: 'Plate Number', width: 150 },
    { field: 'status', headerName: 'Status', width: 120,
        renderCell:(params) => {
            return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        },
    },
]


export const dispatchColumns = [
    { field: 'id', headerName: 'Driver ID', width: 80 },
    { 
        field: 'photo', headerName: 'Photo', width:100,
        renderCell:(params) => {
            return(
                <div className="cellwithphoto">
                    <img src={params.row.img} alt="avatar" className="cellphoto"/>
                    
                </div>
            )
        }
    },
    { field: 'firstname', headerName: 'Firstname', width: 100 },
    { field: 'Lastname', headerName: 'Lastname', width: 100 },
    { field: 'telephone', headerName: 'Phone Number', width: 150 },
    { field: 'platenumber', headerName: 'Plate Number', width: 150 },
    { field: 'status', headerName: 'Status', width: 120,
        renderCell:(params) => {
            return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        },
    },
]


export const deliveryColumns = [
    { field: '@id', headerName: 'ID', width: 80 },
    { 
        field: 'photo', headerName: 'Photo', width:100,
        renderCell:(params) => {
            return(
                <div className="cellwithphoto">
                    <img src={params.row.img} alt="avatar" className="cellphoto"/>
                    
                </div>
            )
        }
    },
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'description', headerName: 'Description', width: 230 },
    { field: 'category', headerName: 'Category', width: 150 },
    { field: 'price', headerName: 'Price (N)', width: 80 },
    { field: 'status', headerName: 'Status', width: 100,
        renderCell:(params) => {
            return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
        },
    },
]


export const contactsColumns = [
  
    { field: 'name', headerName: 'Name', width: 140 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'telephone', headerName: 'Telephone', width: 150 },
    { field: 'subject', headerName: 'Subject', width: 150 },
    { field: 'message', headerName: 'Message', width: 200 },
 
]

export const announcementColumns = [
  
    { field: 'Description', headerName: 'Description', width: 700 },
 
]


export const settingsColumns = [
    
    { field: 'email', headerName: 'Admin Email', width: 230 },
    // { field: 'telephone', headerName: 'Phone Number', width: 150 },
    // { field: 'email', headerName: 'Email Address', width: 150 },
    // { field: 'status', headerName: 'Status', width: 120,
    //     renderCell:(params) => {
    //         return <div className={`cellstatus ${params.row.status}`}>{params.row.status} </div>
    //     },
    // },
]


