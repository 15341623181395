import React, { useEffect, useState, useContext } from 'react'
import './single.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import Chart from '../../../components/chart/Chart'
import { doc, getDoc, getDocs, where, collection, query } from "firebase/firestore"
import { useHistory, useParams } from "react-router-dom"
import { db } from '../../../firebase'
import ApplicantTable from '../../../components/table/ApplicantTable'
import { AuthContext } from "../../../context/AuthContext";
import DataTableUserApplication from "../../../components/dataTable/DataTableUserApplication"
import RichText from '../../../RichText'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Single = ({user}) => {

  const {paymentId} = useParams()

  // const { currentUser } = useContext(AuthContext)


  const [ userData, setUserData ] = useState("")

  const [ paymentData, setPaymentData ] = useState([])

  // const getApplicant = async() => {

  //     const docRef = doc(db, "travellers", applicantId);

  //     const docSnap = await getDoc(docRef);

    
      
  //     if (docSnap.exists()) {

  //       setUserData(docSnap.data())
  //       // console.log(userData)
  //     } else {
  //       // doc.data() will be undefined in this case
  //       console.log("No such document!");
  //     }
  // }

  // useEffect(()=>{
  //   getApplicant()
  // }, [])


  // useEffect(() => {
    
  //   //LISTEN REAL TIME

  //   const applicationQuery = async() => {

  //     const applicationRef = (collection(db, "visa"));
  //     const q = query(applicationRef, where("userId", "==", applicantId));

  //     const querySnapshot = await getDocs(q);
  //       let list = []
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       list.push(doc.data())
  //       // console.log(doc.id, " => ", doc.data());
  //       console.log(list)

  //       setPaymentData(list)
  //       console.log(paymentData)
  //     });

  //     // setPaymentData(doc.data())
  //     console.log(paymentData)

  //   }
    
  //   applicationQuery()   
  // },[])

  // console.log('application info:', paymentData)

  const getPayment = async() => {

    const docRef = doc(db, "visa", paymentId);

    const docSnap = await getDoc(docRef);

        
    if (docSnap.exists()) {

      setPaymentData(docSnap.data() )

    } else {
      
      console.log("No such document!");
    }


}

// console.log(shoppingArray)

 console.log(paymentData)

//  console.log('image: ',validDocument)

 

useEffect(()=>{

  getPayment()

}, [])
 

  return (
    <div className='single'>
      <Sidebar/>
      <div className='singleContainer'>
        <Navbar/>
        <div className="top">
        <div className='topTitle'>

          <div className='topLeft'></div>
            
            <div className='topRight'>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/">
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/payments"
              >
                Payment
              </Link>
              <Typography color="green">Payment Details</Typography>
            </Breadcrumbs>
            </div>
        </div>
         
        </div>

        <div className="bottom">
           
            
            <div className='item'>

            <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                  <span className='itemKey'>Processing Fee / Visa Fee:</span>
                  <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemValue'>{paymentData.currency}{paymentData.total}</span>
                  </div>
            </div> 
            <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                  <span className='itemKey'>Payment ID:</span>
                  <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemValue'>{paymentData.paymentID}</span>
                  </div>
            </div> 

            <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                  <span className='itemKey'>Name:</span>
                  <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemValue'>{paymentData.firstname}</span>
                    <span className='itemValue'>{paymentData.surname}</span>
                  </div>
            </div>

            <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                  <span className='itemKey'>Email:</span>
                  <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemValue'>{paymentData.email}</span>
                  </div>
            </div>
            <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                  <span className='itemKey'>Phone:</span>
                  <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemValue'>{paymentData.phoneNumber}</span>
                  </div>
            </div>
            <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                  <span className='itemKey'>Address:</span>
                  <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemValue'>{paymentData.address}</span>
                  </div>
            </div>
           
            </div>
          </div>
    
      </div>
    </div>
  )
}

export default Single