import React, { useState, useEffect } from 'react'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { db, storage } from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { collection, addDoc, serverTimestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate,  useParams } from "react-router-dom"



const Edit = ({title}) => {

  // const [file, setFile ] = useState("")
  const [ data, setData ] = useState({})
  // const [ perc, setPerc ] = useState(null)
  const [ isloading, setIsloading ] = useState(false)

  const [nairaCharges, setNairaCharges] = useState([])
  const [premiumServiceCharge, setPremiumServiceCharge] = useState()
  const [regularServiceCharge, setRegularServiceCharge] = useState()
  const [visaFee, setVisaFee] = useState() 

  const navigate = useNavigate();

//   const params = useParams()
  const {nairachargesId} = useParams()

//   console.log("charge Id: ", chargeId)


  const Spinner = () => {
    return(
      <span className='spinner-container'>
          <span className='loading-spinner'></span>
      </span>
    )
  }

  const getNairaCharge = async() => {

    const docRef = doc(db, "nairacharges", nairachargesId);

    const docSnap = await getDoc(docRef);

        
    if (docSnap.exists()) {

      setNairaCharges(docSnap.data() )

    } else {
      
      console.log("No such document!");
    }


}

console.log('naira charges: ',nairaCharges)



 

useEffect(()=>{

  getNairaCharge()

}, [])


  


  const handleInput = (e) =>{
    const id = e.target.id;
    const value = e.target.value;

    setData({...nairaCharges, [id]:nairaCharges.id})
  }


  const handleUpdate = async(e) => {

    e.preventDefault()
    setIsloading(true)
  try {
    
    await updateDoc(doc(db, "nairacharges", nairachargesId), {


      premiumServiceCharge : Number(premiumServiceCharge),
      regularServiceCharge : Number(regularServiceCharge),
      visaFee : Number(visaFee),
  });

                
              setIsloading(false)

              navigate('/nairacharges')

          
        } catch (error) {

          console.log('error:',error.message)

        }

}

  
  return (
    <div className="new">
      <Sidebar/>
      <div className="newContainer">
      <Navbar/>
        <div className='top'>
        <div className='topLeft'><h1>{title}</h1></div>
          
          <div className='topRight'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/charges"
            >
              Charges List
            </Link>
            <Typography color="green">Edit Naira Charges</Typography>
          </Breadcrumbs>
          </div>
        </div>
        
        <div className='bottom'>
        
          <div className='right'>
            <form onSubmit={handleUpdate}>

                <div className='formInput' >
                    <label>Premium Service Charge:</label>
                    <input type="text" placeholder="370000" value={premiumServiceCharge}  onChange={e => setPremiumServiceCharge(e.target.value)} id="premiumServiceCharge" required/>
                </div>

                <div className='formInput' >
                    <label>Regular Service Charge:</label>
                    <input type="text" placeholder="310000" value={regularServiceCharge} onChange={e => setRegularServiceCharge(e.target.value)} id="regularServiceCharge" required/>
                </div>

                 <div className='formInput'>
                    <label>Visa Fee:</label>
                    <input type="text" placeholder="230000" value={visaFee}   onChange={e => setVisaFee(e.target.value)} id="visaFee" required/>
                </div>

             <button type='submit'>
              {isloading && <span>...</span>}
              {isloading ? <span>loading</span> : <span>Submit</span>}

             </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Edit
