export const chargesInput = [
    {
        id:"premiumServiceCharge",
        label: "Premium Service Charge",
        type: "text",
        placeholder:"250",
    },
    {
        id:"regularServiceCharge",
        label: "Regular Service Charge",
        type: "text",
        placeholder:"130",
    },
    {
        id:"visaFee", 
        label: "Visa Fee",
        type: "text",
        placeholder:"150",
    },
  

   
];


export const nairaChargesInput = [
    {
        id:"premiumServiceCharge",
        label: "Premium Service Charge",
        type: "text",
        placeholder:"336400",
    },
    {
        id:"regularServiceCharge",
        label: "Regular Service Charge",
        type: "text",
        placeholder:"185750",
    },
    {
        id:"visaFee",
        label: "Visa Fee",
        type: "text",
        placeholder:"227250",
    },
    // {
    //     id:"companyIntroduction",
    //     label: "Company Introduction",
    //     type: "text",
    //     placeholder:"N30000",
    // },
    // {
    //     id:"selfIntroduction",
    //     label: "Self Introduction",
    //     type: "text",
    //     placeholder:"N20000",
    // },
    // {
    //     id:"leaveApproval",
    //     label: "Leave Approval",
    //     type: "text",
    //     placeholder:"N150000",
    // },
    // {
    //     id:"employmentLetter",
    //     label: "Employment Letter",
    //     type: "text",
    //     placeholder:"N21000",
    // },
    // {
    //     id:"visaProcessingFee",
    //     label: "Visa Processing Fee",
    //     type: "text",
    //     placeholder:"N200000",
    // },

   
];


export const riderInput = [
    {
        id:"username",
        label: "Username",
        type: "text",
        placeholder:"Chukwuma2022",
    },
    {
        id:"fullname",
        label: "Fullname",
        type: "text",
        placeholder:"Chukwuma Johnson",
    },
    {
        id:"email",
        label: "Email",
        type: "email",
        placeholder:"Chukwuma_Johnson@gmail.com",
    },
    {
        id:"telephone",
        label: "telePhone",
        type: "text",
        placeholder:"+234 8139 444 402",
    },
    {
        id:"password",
        label: "Password",
        type: "password",
    },
    {
        id:"address",
        label: "Address",
        type: "text",
        placeholder:"6 Abayomi st. Awka",
    },
    {
        id:"state",
        label: "State",
        type: "text",
        placeholder:"Anambra",
    },

];


export const applicantInput = [
    {
        id:"username",
        label: "Username",
        type: "text",
        placeholder:"Chukwuma2022",
    },
    {
        id:"fullname",
        label: "Fullname",
        type: "text",
        placeholder:"Chukwuma Johnson",
    },
    {
        id:"email",
        label: "Email",
        type: "email",
        placeholder:"Chukwuma_Johnson@gmail.com",
    },
    {
        id:"telephone",
        label: "telePhone",
        type: "text",
        placeholder:"+234 8139 444 402",
    },
    {
        id:"password",
        label: "Password",
        type: "password",
    },
    {
        id:"address",
        label: "Address",
        type: "text",
        placeholder:"6 Abayomi st. Awka",
    },
    {
        id:"state",
        label: "State",
        type: "text",
        placeholder:"Anambra",
    },

];

export const dispatchInput = [
    {
        id:"title",
        label: "Title",
        type: "text",
        placeholder:"Food name",
    },
    {
        id:"description",
        label: "Description",
        type: "text",
        placeholder:"Description",
    },
    {
        id:"category",
        label: "category",
        type: "text",
        placeholder:"Fast Food",
    },
    {
        id:"price",
        label: "Price",
        type: parseInt("number"),
        placeholder:"2000",
    },
    {
        id:"stock",
        label: "Stock",
        type: "text",
        placeholder:"available",
    },
  
];

export const driversInput = [
    {
        id:"firstname",
        label: "First Name",
        type: "text",
        placeholder:"Driver's First Name",
    },
    {
        id:"Lastname",
        label: "Last Name",
        type: "text",
        placeholder:"Drivers Last name",
    },
    {
        id:"telephone",
        label: "Telephone",
        type: "text",
        placeholder:"Drivers Telephone Number",
    },
    {
        id:"email",
        label: "Email",
        type: "email",
        placeholder:"Email",
    },
    {
        id:"address",
        label: "Drivers Address",
        type: "text",
        placeholder:"Address",
    },
    {
        id:"nextofkin",
        label: "Next of Kin",
        type: "text",
        placeholder:"Next of Kin",
    },
    {
        id:"nextofkinphonenumber",
        label: "Next of Kin Telephone",
        type: "text",
        placeholder:"Telephone",
    },
    {
        id:"platenumber",
        label: "Bike Plate Number",
        type: "text",
        placeholder:"Plate Number",
    }
    
];

export const notificationInput = [
    {
        id:"title",
        label: "Title",
        type: "text",
        placeholder:"Food name",
    },
    {
        id:"description",
        label: "Description",
        type: "text",
        placeholder:"Description",
    },
    {
        id:"category",
        label: "category",
        type: "text",
        placeholder:"Fast Food",
    },
    {
        id:"price",
        label: "Price",
        type: "text",
        placeholder:"2400",
    },
    {
        id:"stock",
        label: "Stock",
        type: "text",
        placeholder:"available",
    },
  
];

export const settingsInput = [

    {
        id:"email",
        label: "Email",
        type: "text",
        placeholder:"Email Address",
    },
    // {
    //     id:"role",
    //     label: "Role",
    //     type: "text",
    //     placeholder:"admin",
    // }
  
];

export const messagesInput = [
    {
        id:"fullname",
        label: "Fullname",
        type: "text",
        placeholder:"Full name",
    },
  
    {
        id:"message",
        label: "Message",
        type: "text",
        placeholder:"message",
    },
  
    {
        id:"icon",
        label: "icon",
        type: "text",
        placeholder:"icon",
    }
  
];


export const announcementInput = [
    {
        id:"Description",
        label: "Announcement",
        type: "text",
        placeholder:"Description",
    },
  

  
];


