import React, {useState, useEffect} from 'react'
import './single.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import { useParams } from 'react-router-dom'
import { doc, getDoc, updateDoc, onSnapshot, collection, query, orderBy, addDoc, serverTimestamp, deleteField   } from "firebase/firestore"
import { auth, db, storage } from '../../../firebase'
import ApplicationTable from '../../../components/table/ApplicationTable'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { ref, uploadBytesResumable, getDownloadURL,  deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApplicationTableTravelInfo from '../../../components/table/ApplicationTableTravelInfo'
import ApplicationTableMaritalInfo from '../../../components/table/ApplicationTableMaritalInfo'
import ApplicationTableParentInfo from '../../../components/table/ApplicationTableParentInfo'
import ApplicationTableEducationInfo from '../../../components/table/ApplicationTableEducationInfo'
import ApplicationTableEmploymentInfo from '../../../components/table/ApplicationTableEmploymentInfo'
import ApplicationTableTravelHistoryInfo from '../../../components/table/ApplicationTableTravelHistory'
import ApplicationTableDocumentsInfo from '../../../components/table/ApplicationTableDocumentsInfo'
import ApplicationTablePaymentInfo from '../../../components/table/ApplicationTablePaymentInfo'
import ApplicationTableManageDocument from '../../../components/table/ApplicationTableManageDocument'
import ConfirmDecline from '../../../components/ConfirmDecline';

const Single = () => {

  const {applicationId} = useParams()

  const navigate = useNavigate();

  const [file, setFile ] = useState("")

  const [applicant, setApplicant] = useState()

  const [ data, setData ] = useState({})
  const [ perc, setPerc ] = useState(null)

  const [ applicationData, setapplicationData ] = useState([])
  // const [ shopping, setShopping ] = useState([])
  const [ statusUpdate, setStatusUpdate ] = useState("")
  const [ selectQuery, setSelectQuery ] = useState([])
  const [ openModal, setOpenModal ] = useState(false)
  // const [ driverData, setDriverData ] = useState([])
  const [ driverDetails, setDriverDetails ] = useState()
  const [ selectedDriver, setSelectedDriver ] = useState('')
  const [validDocument, setValidDocument] = useState("")
  const [fileError, setFileError] = useState("")

  const [open, setOpen] = useState(false);

  const [declineId, setDecline] = useState("");

  const [url, setUrl] = useState("")

  const [interviewDateError, setInterviewDateError] = useState("")

  const [interviewDate, setInterviewDate] = useState(new Date());

  const closeModal = () => setOpenModal(false)

  useEffect(() => {

    const uploadFile = () => {

      const name = new Date().getTime() + file.name;
      
      const storageRef = ref(storage, name);

      const uploadTask = uploadBytesResumable(storageRef, file);


      uploadTask.on('state_changed', 
        (snapshot) => {
        
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          setPerc(progress)
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        }, 
        (error) => {
          console.log(error)
        }, 
        () => {
         
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // setValidDocument((prev) => ({...prev, downloadURL}))
            setValidDocument(downloadURL)
          });
        }
      );

    };

    file && uploadFile();

  }, [file]);
  
  

  const getVisa = async() => {

      const docRef = doc(db, "visa", applicationId);

      const docSnap = await getDoc(docRef);

          
      if (docSnap.exists()) {

        setapplicationData({...docSnap.data(), children: docSnap.data().children} )

      } else {
        
        console.log("No such document!");
      }


  }


  useEffect(()=>{

    getVisa()

  }, [])

  console.log('APPLICATION: ',applicationData)



// PAYMENT RECEIPT


const paymentMsg = async() => {

  try {

    const docRef = await addDoc(collection(db, "messages"), 
    
    {

      message : " Your payment has been confirmed, thanks for applying through visadoc.",

      firstname : applicationData.firstname,

      surname : applicationData.surname,

      userId : applicationData.userId,

      iconName : "payment",

      status : "unread",

      timeStamp: serverTimestamp(),

    }
  
  )   
  }catch(error){

    console.log(error)

  }

}   
  


const paymentVisa = async() => {
  
const acceptRef = doc(db, "visa", applicationId);

 const updateStatus = updateDoc(acceptRef, {
  status: "RECEIVED"
});

paymentMsg()



const thisStatus = updateStatus.status
console.log(thisStatus)
    

}


  
  // Accept Documents

   console.log(applicationData)

   console.log('image: ',validDocument)

   

    const acceptMsg = async() => {

      try {
  
        const docRef = await addDoc(collection(db, "messages"), 
        
        {
  
          message : "application has been accepted, its now under processing .",
  
          firstname : applicationData.firstname,
  
          surname : applicationData.surname,
  
          userId : applicationData.userId,
  
          iconName : "library-books",
  
          status : "unread",
  
          timeStamp: serverTimestamp(),
  
        }
      
      )   
      }catch(error){
  
        console.log(error)
  
      }
  
    }   
      
    

 const acceptVisa = async() => {
      
  const acceptRef = doc(db, "visa", applicationId);

     const updateStatus = updateDoc(acceptRef, {
     status: "PROCESSING"
    });

    acceptMsg()

    

    const thisStatus = updateStatus.status
    console.log(thisStatus)
        

 }


 // GET STORED IMAGE


 const getValidDocument = () => {

  onSnapshot(doc(db, "visa", applicationId), (doc) => {

  //  console.log("Current data: ", doc.data().travelHistoryPage);

   setUrl(doc.data().validDocument)

 });

}

// useEffect(()=>{

//   getVisaDocument()

// }, [history])



   // deleteDocument


   const deleteDocument = () => {

    const deleteRef = ref(storage, url);

        // Delete the file
        deleteObject(deleteRef).then(async() => {

        const applicationRef = doc(db, 'visa', applicationId);

        await updateDoc(applicationRef, {


          validDocument:"",

          status: "PROCESSING"

        });

        
        Alert.alert("Document deleted successfully")

        // setValidDocument(null)


        // navigation.navigate("CompanyIntroductionImageScreen")

    }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error)
    });

  }


  // PROCESSING VISA


  const processingMsg = async() => {

    try {

      const docRef = await addDoc(collection(db, "messages"), 
      
      {

        message : "We are compiling and processing all required information for your biometric approval.",

        firstname : applicationData.firstname,

        surname : applicationData.surname,

        userId : applicationData.userId,

        iconName : "preview",

        status : "unread",

        timeStamp: serverTimestamp(),

      }
    
    )   
    }catch(error){

      console.log(error)

    }

  }

  

    const processingVisa = async(e) => {

      e.preventDefault()

      if(!validDocument){

          return setFileError("You must upload visa document")
          
      }

      if(interviewDate === ""){

        return setInterviewDateError("You must upload visa document")
        
    }

       try{

         const acceptRef = doc(db, "visa", applicationId);
    
         await updateDoc(acceptRef, {

          validDocument : validDocument,

          interviewDate : interviewDate,

          status: "DELIVERING"



        });

        processingMsg()

        getValidDocument()

        setValidDocument("")

        setFile("")

        closeModal()


        // navigate('/orders')

      }catch (err) {

            console.log(err);

      } 

      approveMsg()

    }


  

  


    // DECLINE 

    const declineMsg = async() => {

      try {
  
        const docRef = await addDoc(collection(db, "messages"), 
        
        {

          message : "your application has been declined.",

          firstname : applicationData.firstname,

          surname : applicationData.surname,

          userId : applicationData.userId,

          iconName : "bookmark-remove",

          status : "unread",

          timeStamp: serverTimestamp(),

        }
      
      )   
      }catch(error){

        console.log(error)

      }

    }
      

          const declineVisa = async() => {
      
            const acceptRef = doc(db, "visa", applicationId);
          
              const updateStatus = await updateDoc(acceptRef, {

                status: "DECLINED"

              });

              declineMsg()
          
           
          }

          // DECLINE HANDLE

          function openDecline(id) {
            setOpen(true);
            setDecline(id);
          }
        
        
        
          const handleDecline = async() => {
        
              declineVisa()
              setOpen(false)
            
          }

          // APPROVED 


          const approveMsg = async() => {

            try {
        
              const docRef = await addDoc(collection(db, "messages"), 
              
              {
      
                message : "Biometrics has been approved, printout for your visa interview.",
      
                firstname : applicationData.firstname,
      
                surname : applicationData.surname,
      
                userId : applicationData.userId,
      
                iconName : "approval",
      
                status : "unread",
      
                timeStamp: serverTimestamp(),
      
              }
            
            )   
            }catch(error){
      
              console.log(error)
      
            }
      
          }  



          const completedMsg = async() => {

            try {
        
              const docRef = await addDoc(collection(db, "messages"), 
              
              {
      
                message : "Congratulations, wishing you success on your interview.",
      
                firstname : applicationData.firstname,
      
                surname : applicationData.surname,
      
                userId : applicationData.userId,
      
                iconName : "recommend",
      
                status : "unread",
      
                timeStamp: serverTimestamp(),
      
              }
            
            )   
            }catch(error){
      
              console.log(error)
      
            }
      
          }  


          const completeVisa = async() => {
      
            const acceptRef = doc(db, "visa", applicationId);
          
              const updateStatus = await updateDoc(acceptRef, {

                status: "APPROVED"

              });

              completedMsg()
          
           
          }


    // QUERY FOR THE VISA

    const getVisaStatus = async() => {

      onSnapshot(doc(db, "visa", applicationId), (doc) => {
      console.log("Current visa: ", doc.data());
      setStatusUpdate(doc.data());
      console.log(statusUpdate);
      console.log(statusUpdate.status)
      const newStatus = statusUpdate.status
      console.log(newStatus)

    });

   
    
    }

    const newStatus = statusUpdate.status 

    useEffect(()=>{
      getVisaStatus()
    }, [])



      //LISTEN REAL TIME
   
      
   

  const Modal = ({open, close}) => {
     
     if(!open) return null
     return(
      <div className='overlay'>
        <div className="modalContainer">
        
          <div className="modalRight">
            <p className="closeBtn" onClick={close}>X</p>
            <div className="content">
              {/* <p className="head">Applicant Document for Visa Interview should be uploaded</p> */}
              <h3 className="driver">Upload <span>{applicationData.country}</span> Valid Document for <span>{applicationData.firstname} {applicationData.surname}</span></h3>

            </div>

            {fileError &&

              <div style={{color:'red', alignItems:'center', display:'flex', justifyContent:'center'}}>{fileError}</div>
            
            }
           

            <div className='formContainer'>

              <div className="left">
              <div className='camerawrap'>

                {
                  file 
                  ?    
                  <img src="../images/travel.png" alt="pic"/> 
                  : 
                  <div style={{width:'100%', height:'100%', justifyContent:'center'}}></div>
                }

              
                
              </div>
              </div>

            <form  onSubmit={processingVisa}>

                    <div className='formInput formAlign'>
                      <label htmlFor='file'>
                        Document:
                      </label>
                      <input type="file" id="file" accept=".jpg, .jpeg, .png, .pdf, .doc" onChange= {e => setFile(e.target.files[0])}/>
                    </div>

                    <div className='formInput formDate'>
                       
                      <label>Interview Date:</label>

                      <DatePicker selected={interviewDate} onChange={(date) => setInterviewDate(date)}/>

                      
                    </div>

                    {fileError &&

                      <div style={{color:'red', alignItems:'center', display:'flex', justifyContent:'center'}}>{interviewDateError}</div>

                      }
               
             
                      <div className="btnContainer">

                        <button className="btnoutline" onClick={close}>
                          <span className="bold">No</span>, Cancel
                        </button>
                        <button className="btnprimary" type='submit' >
                          <span className='bold'>Yes</span>,Continue
                        </button>

                      </div>

               </form>


            </div>
           
          </div>
        </div>
      </div>
     )
}


  return (
    <div className='single'>
      <Sidebar/>
      <div className='singleContainer'>
        <Navbar/>
        <div className='topTitle'>

        <div className='topLeft'><h4>Biometric ID - {applicationData.biometricID}</h4></div>
          
          <div className='topRight'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/applications"
            >
              Application List
            </Link>
            <Typography color="green">Biometric Application Details</Typography>
          </Breadcrumbs>
          </div>
        </div>

        <div className="bottom">
            <h3>Manage {applicationData.surname}  {applicationData.firstname} Bio Status</h3>

            {newStatus === 'APPROVED'
            
            ?

            <div className='completedContainer'>
              <div className='completedWrapper'>
                  <button className= 'completed' disabled> This Document has been Approved Successfully!!!</button>
              </div>
              
            </div>

            :

              <div>
                <div className='buttonTop'>

                {newStatus === 'PAYMENT'

                  ?     <div>
                          <button className= 'buttonPayment' onClick={paymentVisa}>CONFIRM PAYMENT</button>
                        </div>

                  :     <div>
                          <button className= 'payment' disabled onClick={paymentVisa}>PAYMENT CONFIRMED</button>
                        </div>

                }

                </div>
                 <div className='buttonTop'>

                  {newStatus === 'RECEIVED'

                  ?     <div>
                          <button className= 'buttonAccept' onClick={acceptVisa}>ACCEPT APPLICATION</button>
                        </div>

                  :     <div>
                          <button className= 'received' disabled onClick={acceptVisa}>ACCEPT APPLICATION</button>
                        </div>

                  }


                  <div>
                    {/* <button className='buttonDecline' onClick={declineVisa}>DECLINE APPLICATION</button> */}
                    <button className='buttonDecline' onClick={() => openDecline(applicationId)}>DECLINE APPLICATION</button>
                  </div>

                

                  </div>

                  {((newStatus === 'PROCESSING') || (newStatus === 'DELIVERING') || (newStatus === 'DELIVERED')) &&

                  <div className='buttonBottom'>

                  { ((newStatus === 'PROCESSING') || (newStatus === 'DELIVERING') || (newStatus === 'DELIVERED') )

                    ?   
                                                
                        <div>
                          {/* <button className='processing' onClick={processingVisa}>PROCESSING VISA APPLICATION</button> */}
                          <button className='processing' onClick={() => setOpenModal(true)}>PROCESSING VISA DOCUMENT</button> 
                          <Modal open={openModal} close={closeModal}/>
                        </div>

                    :   <div>
                          <button className= 'received' disabled onClick={processingVisa}>PROCESSING VISA APPLICATION</button>
                        </div>

                  }

                 

                  { ((newStatus === 'DELIVERING') || (newStatus === 'DELIVERED'))

                    ?   <div>
                            <hr className='lines-processing'/>
                        </div>

                    :   <div>
                            <hr className='lines-disabledProcessing'/>
                        </div>

                  }

                  <div className='delivering-delete'>

                  { ((newStatus === 'DELIVERING') || (newStatus === 'DELIVERED'))

                  ?   <div>
                        <button className='delivering' >DELIVERING DOCUMENT</button>
                      </div>

                  :   <div>
                        <button className= 'disableDelivering' disabled >DELIVERING DOCUMENT</button>
                      </div>

                  }

                  { ((newStatus === 'DELIVERING') || (newStatus === 'DELIVERED'))

                  ?   
                  
                      <div>
                        <button className='deleteImage' onClick={deleteDocument}>DELETE DOCUMENT</button>
                      </div>

                  :   
                  
                      ""

                  }


                  </div>

               


                  { (newStatus === 'DELIVERED')

                  ?   <div>
                          <hr className='lines-delivering'/>
                      </div>

                  :   <div>
                          <hr className='lines-disabledDelivering'/>
                      </div>

                  }


                  { (newStatus === 'DELIVERED') 

                  ?   <div>
                        <button className='delivered' onClick={completeVisa}>DELIVERED DOCUMENT</button>
                      </div>

                  :   <div>
                        <button className= 'disabledDelivered' disabled >APPLICANT WILL CONFIRM DELIVERY</button>
                      </div>

                  }



                  </div>
                  }
              </div>
            
            }

           
         
        </div>

        {/* Top 1 */}
       
        <div className="top">

          <div className="left">
          <div className='listTitle'><h3>Personal Info</h3></div>
            <div>
               
                <div style={{display:'flex', flexDirection:'column', gap:20}}>
                 
                  <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Firstname:</span>
                    <span className='itemValue'>{applicationData.firstname}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Surname:</span>
                    <span className='itemValue'>{applicationData.surname}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Othername:</span>
                    <span className='itemValue'>{applicationData.othername}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Gender:</span>
                    <span className='itemValue'>{applicationData.gender}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Phone:</span>
                    <span className='itemValue'>{applicationData.phoneNumber}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Address:</span>
                    <span className='itemValue'>{applicationData.address}</span>
                  </div>
              
                </div>
            </div>
          </div>

          <div className='left'>
          
            <div className='listTitle'><h3>Trip Info</h3></div>
              <ApplicationTableTravelInfo
                applicationData= {applicationData}
              />
              
            </div>

          <div className='left'>
          
            <div><h3>Marital Info</h3></div>
              <ApplicationTableMaritalInfo
                applicationData= {applicationData} children={applicationData.children}
              />
              
            </div> 
        </div>

        <div className="top">

        <div className='left'>
          
          <div className='listTitle'><h3>Parent Info</h3></div>
            <ApplicationTableParentInfo
              applicationData= {applicationData}
            />
            
          </div>

          <div className='left'>
          
          <div className='listTitle'><h3>Education Info</h3></div>
            <ApplicationTableEducationInfo
              applicationData= {applicationData}
            />
            
          </div>

          <div className='left'>
          
            <div className='listTitle'><h3>Employment Info</h3></div>
              <ApplicationTableEmploymentInfo
                applicationData= {applicationData}
              />
              
            </div> 
        </div>

        <div className="top">

        <div className='left'>
          
          <div className='listTitle'><h3>Travel History</h3></div>
            <ApplicationTableTravelHistoryInfo
              applicationData= {applicationData}
            />
            
          </div>

          <div className='left'>
          
          <div className='listTitle'><h3>Payment</h3></div>
           
             <ApplicationTablePaymentInfo
                applicationData= {applicationData}
              />
            
          </div>

          <div className='left'>
          
            <div className=''><h3>Documents</h3></div>
              <ApplicationTableDocumentsInfo
                applicationData= {applicationData}
              />
              
            </div> 
        </div>


        <div className="bottom">

        <div className='listTitle'><h2>Manage Documents</h2></div>

              <ApplicationTableManageDocument
                // applicationData= {applicationData}
                applicationId={applicationId}
              />
         
        </div>
       
        </div>

        <ConfirmDecline
          open={open}
          closeDialog={() => setOpen(false)}
          // title={deleteData?.name}
          deleteFunction={handleDecline}
        />
       
      </div>
  )
}

export default Single