import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import './applicationTableEmploymentInfo.scss' 


const  ApplicationTableEmploymentInfo = ({ applicationData}) => {

                                   
 

  return (
            
    <div className=''>

        {
            applicationData.isEmployed === "employed"

            ?

            <div style={{display:'flex', flexDirection:'column', gap:20}}>

              
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Current Company:</span>
                <span className='itemValue'>{applicationData.currentCompany}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Company Address:</span>
                <span className='itemValue'>{applicationData.companyAddress}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Date Of Employment:</span>
                <span className='itemValue'>{applicationData.dateOfEmployment}</span>
            </div>

            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Nature of Job:</span>
                <span className='itemValue'>{applicationData.natureOfJob}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Work Position:</span>
                <span className='itemValue'>{applicationData.workPosition}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Job Duties:</span>
                <span className='itemValue'>{applicationData.jobDuties}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Previous Company:</span>
                <span className='itemValue'>{applicationData.previousCompany}</span>
            </div>
    
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Previous Company Address:</span>
                <span className='itemValue'>{applicationData.previousCompanyAddress}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Previous Nature of Job:</span>
                <span className='itemValue'>{applicationData.previousCompanyNatureOfJob}</span>
            </div>

            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Prvious Nature of Job:</span>
                <span className='itemValue'>{applicationData.dateOfPreviousEmployment}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Previous Job Position:</span>
                <span className='itemValue'>{applicationData.previousJobPosition}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:10}}>
                <span style={{fontWeight:600, color:'gray'}}>Previous Job Duties:</span>
                <span className='itemValue'>{applicationData.previousJobDuties}</span>
            </div>
        
            </div>

            :


            applicationData.isEmployed == "business" 

            ? 

            <div style={{display:'flex', flexDirection:'column', gap:20}}>
                <div style={{display:'flex', flexDirection:'row', gap:10}}>
                    <span style={{fontWeight:600, color:'gray'}}>Employment:</span>
                    <span className='itemValue'>Business</span>
                </div>
                <div style={{display: 'flex', flexDirection:'row', alignContent:'center', gap:60}}>
                    <span style={{fontWeight:600, color:'gray'}}>CAC:</span>
                    <a href={applicationData.cacDocument } download>View</a>
                </div>
                
            </div>
            
            :

            <div style={{display:'flex', flexDirection:'column', gap:10}}>

              
            <div style={{display:'flex', flexDirection:'row', gap:20}}>
                <span style={{fontWeight:600, color:'gray'}}>Employment:</span>
                <span className='itemValue'>Not Employed</span>
            </div>
            
        </div>

        }

        
    </div>
   
  );
}

export default ApplicationTableEmploymentInfo