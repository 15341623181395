import React, { useEffect, useState } from 'react'
import './dataTablePayments.scss'
import { DataGrid } from '@mui/x-data-grid';
import { paymentColumns } from '../../data/dataSource';
import { Link } from 'react-router-dom';
import { collection, getDocs,  deleteDoc, doc, onSnapshot, query, orderBy,  } from "firebase/firestore";
import { db } from '../../firebase'
import swal from 'sweetalert';
import ConfirmBox from '../ConfirmBox';


const DataTable = () => {

  const [ data, setData ] = useState([])

  const [open, setOpen] = useState(false);

  const [deleteId, setDelete] = useState("");

  const [ isLoading, setIsLoading ] = useState(true)


  const Spinner = () => {
    return(
      <div className='spinner-container'>
          <div className='loading-spinner'></div>
      </div>
    )
  }


//   useEffect(() => {

// //LISTEN REAL TIME

//     const collectionRef = collection(db, "travellers")
//     const queryCustomers = query(collectionRef, orderBy("timeStamp", "desc"))

//     const unsub = onSnapshot(queryCustomers, (snapShot) => {
//       let list = [];
//       snapShot.docs.forEach((doc) => {
//         list.push({ id: doc.id, ...doc.data(), timeStamp: doc.data().timeStamp.toDate()})
//       })
//       setData(list)
//       setIsLoading(false)
//     }, 
//       (error) => {
//         console.log(error)
//       }
//     );

//     return () => {
//       unsub();
//     }

// //END LISTEN REAL TIME    

//   },[])


  useEffect(() => {
   
    
        const collectionRef = collection(db, "visa")
        const queryApplication = query(collectionRef, orderBy("timeStamp", "desc"))
        // const queryDate = query(queryApplication, where("status", '!=', "ORDER_COMPLETED"))
        const unsub = onSnapshot(queryApplication, (snapShot) => {
          let list = [];
          snapShot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data(), timeStamp: doc.data().timeStamp.toDate()})
            
          })
          setData(list)
          setIsLoading(false)
    
          // console.log("user created at:", data.createdAt.toDate());
        }, 
          (error) => {
            console.log(error)
          }
        );
    
        return () => {
          unsub();
        }
    
    // //END LISTEN REAL TIME    
    
  },[])




  console.log(data)

  function openDelete(id) {
    setOpen(true);
    setDelete(id);
  }



  const handleDelete = async() => {

    
      await deleteDoc(doc(db, "visa", deleteId));
      setData(data.filter((item) => item.id !== deleteId))

      setOpen(false)
    
  }

  const actionColumn = [

    { field: 'action', headerName: 'Action', width: 130, 
    
            renderCell:(params) => {
                return(
                    <div className='cellAction'>
                       <Link to={`/payments/${params.row.id}`} style={{textDecoration:"none"}}>
                          <div className="viewbutton">View</div>
                       </Link>

                       <Link
                            // onClick={() => openDelete(item)}
                            onClick={() => openDelete(params.row.id)}
                            to={"#"}
                            className="deletebutton"
                          >
                            Delete
                       </Link>
                       
                    </div>
                )
            }
    }
      
  ]  

  return (
    <>
    
      <div className="datatable">
        <div className='datatableTitle'>
          All Payments
          {/* <Link to="/applicants/new"  style={{textDecoration:"none"}} >
            <div className='link'>
              Add New Applicant
            </div>
          </Link> */}
        </div>
        {isLoading 
        ? <Spinner/>
        :<DataGrid
            className="applicantTableGrid"
            rows={data}
            columns={paymentColumns.concat(actionColumn)}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection
          />
        }
      </div>

      <ConfirmBox
      open={open}
      closeDialog={() => setOpen(false)}
      // title={deleteData?.name}
      deleteFunction={handleDelete}
      />
    </>

  )
}

export default DataTable