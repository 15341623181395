import React, { useState, useEffect } from 'react'
import './applicationTableManageDocument.scss' 
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { doc, getDoc, updateDoc} from "firebase/firestore"
import { db} from '../../firebase'
import { internationalPassportOption } from '../../data/dataLocal';


const ApplicationTableManageDocument = ({  applicationId}) => {

    

    const [availableInternationalPassport, setAvailableInternationalPassport] = React.useState("");
    const [availablePassportPhotograph, setAvailablePassportPhotograph] = React.useState("");
    const [availableBirthCertificate, setAvailableBirthCertificate] = React.useState("");
    const [availableMarriageCertificate, setAvailableMarriageCertificate] = React.useState("");
    const [availableStatementOfAccount, setAvailableStatementOfAccount] = React.useState("");
    const [availableCompanyIntroduction, setAvailableCompanyIntroduction] = React.useState("");
    const [availableSelfIntroduction, setAvailableSelfIntroduction] = React.useState("");
    const [availableLeaveApprovalLetter, setAvailableLeaveApprovalLetter] = React.useState("");
    const [availableEmploymentLetter, setAvailableEmploymentLetter] = React.useState("");

   

    const [applicationData, setapplicationData] = useState([])


    // const getDocumentStatus = async() => {

    //     const docRef = doc(db, "visa", applicationId);
  
    //     const docSnap = await getDoc(docRef);
  
            
    //     if (docSnap.exists()) {
  
    //       setapplicationData({...docSnap.data(), children: docSnap.data().children} )
  
    //     } else {
          
    //       console.log("No such document!");
    //     }
  
  
    // }
  
  
    // useEffect(()=>{
  
    //   getDocumentStatus()
  
    // }, [])




    // Available Documents

    // const handleChangeAvailableInternationalPassport = (item) => {

    //     setAvailableInternationalPassport(item);
        
    //     const internationalPassportRef = doc(db, "visa", applicationId);
    //     updateDoc(internationalPassportRef, {
    //         availableInternationalPassport: applicationData.availableInternationalPassport
    //       });

          
    // };

    const handleChangeAvailableInternationalPassport = (event) => {

        setAvailableInternationalPassport(event.target.value);
        
        const internationalPassportRef = doc(db, "visa", applicationId);
        updateDoc(internationalPassportRef, {
            availableInternationalPassport: availableInternationalPassport
          });

          
    };

    const handleChangeAvailablePassportPhotograph = (event) => {

        setAvailablePassportPhotograph(event.target.value);
        
        const passportPhotographRef = doc(db, "visa", applicationId);
        updateDoc(passportPhotographRef, {
            availablePassportPhotograph: availablePassportPhotograph
        });

          
    };

    const handleChangeAvailableBirthCertificate = (event) => {

        setAvailableBirthCertificate(event.target.value);

        const birthCertificateRef = doc(db, "visa", applicationId);
        updateDoc(birthCertificateRef, {
            availableBirthCertificate: availableBirthCertificate
        });
        

    };

      const handleChangeAvailableMarriageCertificate = (event) => {

        setAvailableMarriageCertificate(event.target.value);

        const marriageCertificateRef = doc(db, "visa", applicationId);
        updateDoc(marriageCertificateRef, {
            availableMarriageCertificate: availableMarriageCertificate
        });
        
      };

      const handleChangeAvailableStatementOfAccount = (event) => {

        setAvailableStatementOfAccount(event.target.value);

        const statementOfAccountRef = doc(db, "visa", applicationId);
        updateDoc(statementOfAccountRef, {
            availableStatementOfAccount: availableStatementOfAccount
        });
        
      };

      const handleChangeAvailableCompanyIntroduction = (event) => {

        setAvailableCompanyIntroduction(event.target.value);

        const companyIntroductionRef = doc(db, "visa", applicationId);
        updateDoc(companyIntroductionRef, {
            availableCompanyIntroduction: availableCompanyIntroduction
        });
        
      };

      const handleChangeAvailableSelfIntroduction = (event) => {
        setAvailableSelfIntroduction(event.target.value);

        const selfIntroductionRef = doc(db, "visa", applicationId);
        updateDoc(selfIntroductionRef, {
            availableSelfIntroduction: availableSelfIntroduction
        });
       
      };

      const handleChangeAvailableLeaveApprovalLetter = (event) => {

        setAvailableLeaveApprovalLetter(event.target.value);

        const leaveApprovalLetterRef = doc(db, "visa", applicationId);
        updateDoc(leaveApprovalLetterRef, {
            availableLeaveApprovalLetter: availableLeaveApprovalLetter
        });
        
      };

      const handleChangeAvailableEmploymentLetter = (event) => {
        setAvailableEmploymentLetter(event.target.value);

        const employmentLetterRef = doc(db, "visa", applicationId);
        updateDoc(employmentLetterRef, {
            availableEmploymentLetter: availableEmploymentLetter
        });
        
      };

      
      const getVisaInfo = async() => {
      const docRef = doc(db, "visa", applicationId);

            const docSnap = await getDoc(docRef);

                
            if (docSnap.exists()) {

            setapplicationData(docSnap.data() )

            } else {
            
            console.log("No such document!");
            }


        }



        // console.log(applicationData)

        // console.log('image: ',validDocument)

        

        useEffect(()=>{

        getVisaInfo()

        }, [])
  


        const downloadImage = (url) =>{

        
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.download = "downloaded-file";
              document.body.appendChild(link);
      
              link.click();
      
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("Error fetching the file:", error);
            });
       

        }


  return (
            
    <div className=''>

        
    <div >
        <div >

        <h4 style={{fontSize:18}}>Available Documents</h4>


          {
                applicationData.internationalPassport === "available"
                &&
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                    <div style={{display:'flex', flexDirection:'row', width:200}}>
                        <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>International Passport:</span>
                    </div>
                    

                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                     <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableInternationalPassport}
                            onChange={handleChangeAvailableInternationalPassport}
                        >
                           <FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div> 
                  
                   
                </div>
               
            } 


         

                 {
                applicationData.passportPhotograph === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                    <div style={{display:'flex', flexDirection:'row', width:200}}>
                        <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Passport Photograph:</span>
                        {/* <span className='itemValue'>{applicationData.passportPhotograph}</span> */}
                    </div>
                    

                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.passportPhotographImage} download>View</a> */}
                    <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availablePassportPhotograph}
                            onChange={handleChangeAvailablePassportPhotograph}
                        >
                           <FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div>
                  
                   
                </div>
                :

                ""
            }     

       

            {
                applicationData.birthCertificate  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                <div style={{display:'flex', flexDirection:'row', width:200}}>
                    <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Birth Certificate:</span>
                    {/* <span className='itemValue'>{applicationData.birthCertificate }</span> */}
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.birthCertificateImage } download>View</a> */}

                    <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableBirthCertificate}
                            onChange={handleChangeAvailableBirthCertificate}
                        >
                           <FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div>
                  
                   
                </div>
                :

                ""
            }  

             

            {
                applicationData.marriageCertificate  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                <div style={{display:'flex', flexDirection:'row', width:200}}>
                    <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Marriage Certificate:</span>
                    {/* <span className='itemValue'>{applicationData.marriageCertificate}</span> */}
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.marriageCertificateImage} download>View</a> */}

                    <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableMarriageCertificate}
                            onChange={handleChangeAvailableMarriageCertificate}
                            ><FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div>
                  
                   
                </div>
                :

                ""
            }  


           

            {
                applicationData.statementOfAccount  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                <div style={{display:'flex', flexDirection:'row', width:200}}>
                    <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Statement of Account:</span>
                    {/* <span className='itemValue'>{applicationData.statementOfAccount}</span> */}
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.statementOfAccountImage} download>View</a> */}

                    <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableStatementOfAccount}
                            onChange={handleChangeAvailableStatementOfAccount}
                        ><FormControlLabel value="pending" control={<Radio />} label="Pending" />
                        <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                        <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div>
                  
                   
                </div>
                :

                ""
            }  


            


            {
                applicationData.companyIntroduction  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                <div style={{display:'flex', flexDirection:'row', width:200}}>
                    <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Company Introduction Letter:</span>
                    {/* <span className='itemValue'>{applicationData.companyIntroduction}</span> */}
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.companyIntroductionImage} download>View</a> */}

                    <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableCompanyIntroduction}
                            onChange={handleChangeAvailableCompanyIntroduction}
                        >
                          <FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div>
                  
                   
                </div>
                :

                ""
            }  

           


            {
                applicationData.selfIntroduction  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                <div style={{display:'flex', flexDirection:'row', width:200}}>
                    <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Self Introduction Letter:</span>
                    {/* <span className='itemValue'>{applicationData.selfIntroduction}</span> */}
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.selfIntroductionImage} download>View</a> */}

                    <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableSelfIntroduction}
                            onChange={handleChangeAvailableSelfIntroduction}
                        >
                           <FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div>
                  
                   
                </div>
                :

                ""
            }  



            {
                applicationData.leaveApprovalLetter  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                <div style={{display:'flex', flexDirection:'row', width:200}}>
                    <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Leave Approval Letter:</span>
                    {/* <span className='itemValue'>{applicationData.leaveApprovalLetter}</span> */}
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.leaveApprovalLetterImage} download>View</a> */}

                    <div>
                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableLeaveApprovalLetter}
                            onChange={handleChangeAvailableLeaveApprovalLetter}
                        >
                           <FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>
                    </div>

                    </div>
                  
                   
                </div>
                :

                ""
            }  

             

            {
                applicationData.employmentLetter  === "available"
                ?
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                <div style={{display:'flex', flexDirection:'row', width:200}}>
                    <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>Employment Letter:</span>
                    {/* <span className='itemValue'>{applicationData.employmentLetter}</span> */}
                </div>
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                    {/* <a href={applicationData.employmentLetterImage} download>View</a> */}

                         <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={availableEmploymentLetter}
                            onChange={handleChangeAvailableEmploymentLetter}
                        >
                            <FormControlLabel value="pending" control={<Radio />} label="Pending" />
                            <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                            <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </RadioGroup>
                        </FormControl>

                    </div>
                  
                   
                </div>
                :

                ""
            }  


           


            {/* {
                applicationData.internationalPassport === "available"
                &&
                <div  style={{display:'flex', flexDirection:'row', marginBottom:10, alignItems:'center', gap:50}}>
                    <div style={{display:'flex', flexDirection:'row', width:200}}>
                        <span style={{fontWeight:'bold', marginRight:5, color:"#00000088"}}>International Passport:</span>
                    </div>
                    

                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:10}}>

                        {
                            internationalPassportOption.map(item => 
                                 (
                                         <div   key={item.id} >
                                            <button style={{ padding:10, backgroundColor: applicationData.availableInternationalPassport === item.value? 'lightgreen':'white', color:'black', fontWeight:'bold', fontSize:13, borderRadius:5, cursor:'pointer' }} onClick={() => handleChangeAvailableInternationalPassport(item.value)}>{item.title}</button>
                                         </div>
                                )
                            )
                        }

                    </div> 
                  
                   
                </div>
               
            }     */}
            
          
        
        </div>

    </div>  

    <div>     

    </div>
    </div>
   
  );
}


export default ApplicationTableManageDocument