import React, {useState, useEffect} from 'react'
import './single.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
// import Chart from '../../../components/chart/Chart'
// import SalesTable from '../../../components/table/SalesTable'
import { useParams } from 'react-router-dom'
import { doc, getDoc, updateDoc, onSnapshot, collection, query, orderBy } from "firebase/firestore"
import { auth, db, storage } from '../../../firebase'
import ApplicationTable from '../../../components/table/ApplicationTable'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom"
import { Navigation } from '@mui/icons-material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// import driver from './images/onboardingontheway.png'
// import driver from './animations/dispatch-rider.json';
import ApplicationTableTravelInfo from '../../../components/table/ApplicationTableTravelInfo'
import ApplicationTableMaritalInfo from '../../../components/table/ApplicationTableMaritalInfo'
import ApplicationTableParentInfo from '../../../components/table/ApplicationTableParentInfo'
import ApplicationTableEducationInfo from '../../../components/table/ApplicationTableEducationInfo'
import ApplicationTableEmploymentInfo from '../../../components/table/ApplicationTableEmploymentInfo'
import ApplicationTableTravelHistoryInfo from '../../../components/table/ApplicationTableTravelHistory'
import ApplicationTableDocumentsInfo from '../../../components/table/ApplicationTableDocumentsInfo'
import ApplicationTablePaymentInfo from '../../../components/table/ApplicationTablePaymentInfo'

const Single = () => {

  const {applicationHistoryId} = useParams()

  const navigate = useNavigate();

  const [file, setFile ] = useState("")

  const [ data, setData ] = useState({})
  const [ perc, setPerc ] = useState(null)

  const [ applicationData, setapplicationData ] = useState([])
  // const [ shopping, setShopping ] = useState([])
  const [ statusUpdate, setStatusUpdate ] = useState("")
  const [ selectQuery, setSelectQuery ] = useState([])
  const [ openModal, setOpenModal ] = useState(false)
  // const [ driverData, setDriverData ] = useState([])
  const [ driverDetails, setDriverDetails ] = useState()
  const [ selectedDriver, setSelectedDriver ] = useState('')
  const [validDocument, setValidDocument] = useState("")
  const [fileError, setFileError] = useState("")

  const closeModal = () => setOpenModal(false)

  useEffect(() => {

    const uploadFile = () => {

      const name = new Date().getTime() + file.name;
      
      const storageRef = ref(storage, name);

      const uploadTask = uploadBytesResumable(storageRef, file);


      uploadTask.on('state_changed', 
        (snapshot) => {
        
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          setPerc(progress)
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        }, 
        (error) => {
          console.log(error)
        }, 
        () => {
         
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setValidDocument((prev) => ({...prev, downloadURL}))
          });
        }
      );

    };

    file && uploadFile();

  }, [file]);
  
  
  const getVisa = async() => {

      const docRef = doc(db, "visa", applicationHistoryId);

      const docSnap = await getDoc(docRef);

          
      if (docSnap.exists()) {

        setapplicationData(docSnap.data() )

      } else {
        
        console.log("No such document!");
      }


  }

  // console.log(shoppingArray)

   console.log(applicationData)

   console.log('image: ',validDocument)

   

  useEffect(()=>{

    getVisa()

  }, [])

  console.log('APPLICATION: ',applicationData)


 const acceptVisa = async() => {
      
  const acceptRef = doc(db, "visa", applicationHistoryId);

     const updateStatus = updateDoc(acceptRef, {
      status: "PROCESSING"
    });

    const thisStatus = updateStatus.status
    console.log(thisStatus)
        

 }




  // PROCESSING VISA

    const processingVisa = async(e) => {

      e.preventDefault()

      if(!validDocument){

          return setFileError("You must upload visa document")
          
      }

       try{

         const acceptRef = doc(db, "visa", applicationHistoryId);
    
         await updateDoc(acceptRef, {

          visaDocument : validDocument,

          status: "DELIVERING"



        });

        closeModal()
        // navigate('/orders')

      }catch (err) {

            console.log(err);

      } 

    }
      

          const declineVisa = async() => {
      
            const acceptRef = doc(db, "visa", applicationHistoryId);
          
              const updateStatus = await updateDoc(acceptRef, {
                status: "DECLINED"
              });
          
           
          }

         

          const completeVisa = async() => {
      
            const acceptRef = doc(db, "visa", applicationHistoryId);
          
              const updateStatus = await updateDoc(acceptRef, {
                status: "APPROVED"
              });
          
           
          }


    // QUERY FOR THE VISA

    const getVisaStatus = async() => {

      onSnapshot(doc(db, "visa", applicationHistoryId), (doc) => {
      console.log("Current visa: ", doc.data());
      setStatusUpdate(doc.data());
      console.log(statusUpdate);
      console.log(statusUpdate.status)
      // console.log(applicationHistoryId)
      const newStatus = statusUpdate.status
      console.log(newStatus)

    });

   
    
    }

    const newStatus = statusUpdate.status 

    useEffect(()=>{
      getVisaStatus()
    }, [])



      //LISTEN REAL TIME
   
      
   

  const Modal = ({open, close}) => {
     
     if(!open) return null
     return(
      <div className='overlay'>
        <div className="modalContainer">
          {/* <img src="./images/onboardingontheway.png" alt="driver" /> */}
          {/* <div className="modalLeft" style={{display:'flex', flexDirection:'column', alignContent:'center', justifyContent:"center", 
          }}>
          <img
                src = {selectedDriver.img}
                alt="avatar"
                style={{width:'100px', height:'100px', borderRadius:'50%', marginBottom:'10px'}}
                   
            /> 
            <p>{selectedDriver.firstname}</p>
            <p>{selectedDriver.Lastname}</p>
            <p>{selectedDriver.telephone}</p>
          </div> */}
          <div className="modalRight">
            <p className="closeBtn" onClick={close}>X</p>
            <div className="content">
              {/* <p className="head">Applicant Document for Visa Interview should be uploaded</p> */}
              <h3 className="driver">Upload <span>{applicationData.country}</span> Visa Document for <span>{applicationData.firstname} {applicationData.surname}</span></h3>

            </div>

            {fileError &&

              <div style={{color:'red', alignItems:'center', display:'flex', justifyContent:'center'}}>{fileError}</div>
            
            }
           

            <div className='formContainer'>

              <div className="left">
              <div className='camerawrap'>

                {
                  file 
                  ?    
                  <img
                     src="../images/travel.png" alt="pic"
                  /> 
                  : 
                  perc
                }

              
                
              </div>
              </div>

            <form  onSubmit={processingVisa}>

                    <div className='formInput formAlign'>
                      <label htmlFor='file'>
                        Document:
                      </label>
                      <input type="file" id="file" accept=".jpg, .jpeg, .png, .pdf, .doc" onChange= {e => setFile(e.target.files[0])}/>
                    </div>

                    {/* <div className='formInput' >
                       
                      <label>Applicant Name:</label>

                      <span>{applicationData.firstname} {applicationData.surname}</span>
                      
                    </div> */}
                    {/* <div className='formInput' >
                       
                      <label>Destination:</label>

                      <span>{applicationData.country}</span>
                      
                    </div> */}
             
                      <div className="btnContainer">

                        <button className="btnoutline" onClick={close}>
                          <span className="bold">No</span>, Cancel
                        </button>
                        <button className="btnprimary" type='submit' >
                          <span className='bold'>Yes</span>,Continue
                        </button>

                      </div>

               </form>


            </div>
           
          </div>
        </div>
      </div>
     )
}


  return (
    <div className='single'>
      <Sidebar/>
      <div className='singleContainer'>
        <Navbar/>
        <div className='topTitle'>

        <div className='topLeft'><h4>Visa Application ID - {applicationData.visaId}</h4></div>
          
          <div className='topRight'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/applications"
            >
              Application List
            </Link>
            <Typography color="green">Visa Application Details</Typography>
          </Breadcrumbs>
          </div>
        </div>

        <div className="bottom">
            <h3>Manage {applicationData.surname}  {applicationData.firstname} Visa Status</h3>

            {newStatus === 'APPROVED'
            
            ?

            <div className='completedContainer'>
              <div className='completedWrapper'>
                  <button className= 'completed' disabled> This Visa has been Approved Successfully!!!</button>
              </div>
              
            </div>

            :

              <div>
                 <div className='buttonTop'>

                  {newStatus === 'RECEIVED'

                  ?     <div>
                          <button className= 'buttonAccept' onClick={acceptVisa}>ACCEPT APPLICATION</button>
                        </div>

                  :     <div>
                          <button className= 'received' disabled onClick={acceptVisa}>ACCEPT APPLICATION</button>
                        </div>

                  }


                  <div>
                    <button className='buttonDecline' onClick={declineVisa}>DECLINE APPLICATION</button>
                  </div>

                  </div>

                  {((newStatus === 'PROCESSING') || (newStatus === 'DELIVERING') || (newStatus === 'DELIVERED')) &&

                  <div className='buttonBottom'>

                  { ((newStatus === 'PROCESSING') || (newStatus === 'DELIVERING') || (newStatus === 'DELIVERED') )

                    ?   
                                                
                        <div>
                          {/* <button className='processing' onClick={processingVisa}>PROCESSING VISA APPLICATION</button> */}
                          <button className='processing' onClick={() => setOpenModal(true)}>PROCESSING VISA DOCUMENT</button> 
                          <Modal open={openModal} close={closeModal}/>
                        </div>

                    :   <div>
                          <button className= 'received' disabled onClick={processingVisa}>PROCESSING VISA APPLICATION</button>
                        </div>

                  }


                  { ((newStatus === 'DELIVERING') || (newStatus === 'DELIVERED'))

                    ?   <div>
                            <hr className='lines-processing'/>
                        </div>

                    :   <div>
                            <hr className='lines-disabledProcessing'/>
                        </div>

                  }


                  { ((newStatus === 'DELIVERING') || (newStatus === 'DELIVERED'))

                  ?   <div>
                        <button className='delivering' >DELIVERING DOCUMENT</button>
                      </div>

                  :   <div>
                        <button className= 'disableDelivering' disabled >DELIVERING DOCUMENT</button>
                      </div>

                  }


                  { (newStatus === 'DELIVERED')

                  ?   <div>
                          <hr className='lines-delivering'/>
                      </div>

                  :   <div>
                          <hr className='lines-disabledDelivering'/>
                      </div>

                  }


                  { (newStatus === 'DELIVERED') 

                  ?   <div>
                        <button className='delivered' onClick={completeVisa}>DEVLIVERED DOCUMENT</button>
                      </div>

                  :   <div>
                        <button className= 'disabledDelivered' disabled >APPLICANT WILL CONFIRM DELIVERY</button>
                      </div>

                  }



                  </div>
                  }
              </div>
            
            }

           
         
        </div>

        {/* Top 1 */}
       
        <div className="top">

          <div className="left">
            
            <div className='item'>
               
                <div className="details">
                  <h3 className='itemTitle'>{applicationData.firstname} {applicationData.surname}</h3>
                  <div className='detailItem'>
                    <span className='itemKey'>Email:</span>
                    <span className='itemValue'>{applicationData.email}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Phone:</span>
                    <span className='itemValue'>{applicationData.phoneNumber}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Address:</span>
                    <span className='itemValue'>{applicationData.address}</span>
                  </div>
              
                </div>
            </div>
          </div>

          <div className='middle'>
          
            <div className='listTitle'><h3>Travel Info</h3></div>
              <ApplicationTableTravelInfo
                applicationData= {applicationData}
              />
              
            </div>

          <div className='right'>
          
            <div className='listTitle'><h3>Marital Info</h3></div>
              <ApplicationTableMaritalInfo
                applicationData= {applicationData}
              />
              
            </div> 
        </div>

        <div className="top">

        <div className='left'>
          
          <div className='listTitle'><h3>Parent Info</h3></div>
            <ApplicationTableParentInfo
              applicationData= {applicationData}
            />
            
          </div>

          <div className='middle'>
          
          <div className='listTitle'><h3>Education Info</h3></div>
            <ApplicationTableEducationInfo
              applicationData= {applicationData}
            />
            
          </div>

          <div className='right'>
          
            <div className='listTitle'><h3>Employment Info</h3></div>
              <ApplicationTableEmploymentInfo
                applicationData= {applicationData}
              />
              
            </div> 
        </div>

        <div className="top">

        <div className='left'>
          
          <div className='listTitle'><h3>Travel History</h3></div>
            <ApplicationTableTravelHistoryInfo
              applicationData= {applicationData}
            />
            
          </div>

          <div className='middle'>
          
          <div className='listTitle'><h3>Payment</h3></div>
           
             <ApplicationTablePaymentInfo
                applicationData= {applicationData}
              />
            
          </div>

          <div className='right'>
          
            <div className='listTitle'><h3>Documents</h3></div>
              <ApplicationTableDocumentsInfo
                applicationData= {applicationData}
              />
              
            </div> 
        </div>
                  
       
        </div>
       
      </div>
  )
}

export default Single