import React, { useContext, useState, useEffect } from 'react'
import './navbar.scss'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { DarkModeContext } from '../../context/darkModeContext';
import { AuthContext } from '../../context/AuthContext'
import { collection, getDoc, getDocs,  deleteDoc, doc, onSnapshot, query, orderBy, getAggregateFromServer, snapshot,  where } from "firebase/firestore";
import { db } from '../../firebase'
import {Link} from 'react-router-dom'

const Navbar = () => {

 const {dispatch} = useContext(DarkModeContext)
 const { currentUser } = useContext(AuthContext)

 const [ adminData, setAdminData ] = useState([])
 const [applicationCount, setApplicationCount] = useState(null)

 const [messageCount, setMessageCount] = useState(null)

 const getAdmin = async() => {

  const docRef = doc(db, "travellers", currentUser.uid);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {

    setAdminData(docSnap.data())
    // console.log(userData)
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");

  }

}

useEffect(()=>{

  getAdmin()

}, [])



const alertsAndNotifications = async() => {

  // const travellersQuery = collection(db, "travellers")
  // const allTravellers = await getDocs(travellersQuery)
  // setApplicant(allTravellers.docs.length)

  // const visaQuery = collection(db, "visa")
  // const allVisa = await getDocs(visaQuery)
  // setApplication(allVisa.docs.length)

  
  // const t = query(collection(db, "travellers"), where("registered", "==", "true"));
  // const querySnapshot = await getDocs(q);
  // setApplicationCount(querySnapshot.docs.length)
  
  const q = query(collection(db, "visa"), where("status", "==", "PAYMENT"));
  const querySnapshot = await getDocs(q);
  setApplicationCount(querySnapshot.docs.length)



// querySnapshot.forEach((doc) => {
//   console.log(doc.id, " => ", doc.data());
// });

}

useEffect(() => {
  alertsAndNotifications()
}, [])


 console.log(currentUser)

 console.log('CURRENT USER',currentUser.uid)

 // Count Applications

// const applicationCounter = async() => {

//   const coll = collection(db, "visa");
//   const q = query(coll, where("status", "==", "RECEIVED"));
//   const snapshot = await getCountFromServer(q);

//   setApplicationCount(snapshot.data().count)

//   console.log('count: ', snapshot.data().count);


// }

// useEffect(() => {

//   applicationCounter()

// }, [])

// console.log('application count', applicationCount)

const messagesCount = async() => {

  const q = query(collection(db, "contact"), where("status", "==", "UNREAD"));
  const querySnapshot = await getDocs(q);
  setMessageCount(querySnapshot.docs.length)


}

useEffect(() => {
  messagesCount()
}, [])




  return (
    <div className="navbar">
      <div className="wrapper">
          <div className="search">
            <input type="text" placeholder='Search...' />
            <SearchOutlinedIcon/>
          </div>
          <div className="items">
            <div className="item">
              <LanguageOutlinedIcon className='icon'/>
              English
            </div>
            <div className="item">
              < DarkModeOutlinedIcon className='icon' onClick={() => dispatch({type:'TOGGLE'})}/>
            </div>
            {/* <div className="item">
              <FullscreenExitOutlinedIcon className='icon'/>
            </div> */}
            <div className="item">
            <Link to='/applications'>
              <NotificationsNoneOutlinedIcon className='icon'/>
              {applicationCount ?

                  <div className="counter">
                  {applicationCount}
                  </div>

                  :
                  
                  ""

              }
              
              </Link>
            </div>
            <div className="item">
              <Link to='/messages'>
              <ChatBubbleOutlineOutlinedIcon className='icon'/>
                {messageCount ?

                  <div className="counter">
                  {messageCount}
                  </div>

                  :
                  
                  ""

              }
              </Link>
            </div>
           
            <div className="item">
              <img 
                src='./images/user.png'
                className='pic'
              />
            </div>
            <div className="item">
              <span>{adminData.firstname}</span>
            </div>

          </div> 
      </div>     
    </div>
  )
}

export default Navbar