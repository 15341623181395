import React, { useState, useEffect } from 'react'
import './new.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { db, storage } from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom"
import LunchDiningIcon from '@mui/icons-material/LunchDining';



const New = ({inputs, title}) => {

  const [file, setFile ] = useState("")
  const [ data, setData ] = useState({})
  const [ perc, setPerc ] = useState(null)
  const [ isloading, setIsloading ] = useState(false)

  const navigate = useNavigate();


  const Spinner = () => {
    return(
      <span className='spinner-container'>
          <span className='loading-spinner'></span>
      </span>
    )
  }

  

  // useEffect(() => {
  //   const uploadFile = () => {

  //     const name = new Date().getTime() + file.name;
      
  //     const storageRef = ref(storage, name);

  //     const uploadTask = uploadBytesResumable(storageRef, file);

  //     uploadTask.on('state_changed', 
  //       (snapshot) => {
        
  //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         console.log('Upload is ' + progress + '% done');
  //         setPerc(progress)
  //         switch (snapshot.state) {
  //           case 'paused':
  //             console.log('Upload is paused');
  //             break;
  //           case 'running':
  //             console.log('Upload is running');
  //             break;
  //           default:
  //             break;
  //         }
  //       }, 
  //       (error) => {
  //         console.log(error)
  //       }, 
  //       () => {
         
  //         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //           setData((prev) => ({...prev, img: downloadURL}))
  //         });
  //       }
  //     );

  //   };

  //   file && uploadFile();

  // }, [file]);

  const handleInput = (e) =>{
    const id = e.target.id;
    const value = e.target.value;

    setData({...data, [id]:value})
  }

  
  const handleAdd = async(e) => {

    e.preventDefault()
    setIsloading(true)

    try{
       
       
       await addDoc(collection(db, "nairacharges"), {
        ...data,
        timeStamp: serverTimestamp(),
        

      });
      
      setIsloading(false)

      navigate('/nairacharges')
      
      
    }catch (err) {
      console.log(err);
    } 

  }

  return (
    <div className="new">
      <Sidebar/>
      <div className="newContainer">
      <Navbar/>
        <div className='top'>
        <div className='topLeft'><h1>{title}</h1></div>
          
          <div className='topRight'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/nairacharges"
            >
              Naira Charges List
            </Link>
            <Typography color="green">Add Charges</Typography>
          </Breadcrumbs>
          </div>
        </div>
        
        <div className='bottom'>
          {/* <div className="left"> */}
            {/* <div className='camerawrap'> */}

              {/* <img
                src={file 
                  ? URL.createObjectURL(file) 
                  : "../images/user.png"}
                alt=""
                className='imgs'
                resizeMode = "contain"
              /> */}
              
            {/* </div> */}
          {/* </div> */}
          <div className='right'>
            <form onSubmit={handleAdd}>
            
              {inputs.map((input, index) =>

                     <div className='formInput' key={index.toString()} >
                        
                       <label>{input.label}:</label>
                       <input type={input.type} placeholder={input.placeholder} onChange={handleInput} id={input.id} required/>
                     </div>
              
              )}

             <button type='submit'>
              {isloading && <span>...</span>}
              {isloading ? <span>loading</span> : <span>Submit</span>}

              
             </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default New














// import React, { useState, useEffect } from 'react'
// import './new.scss'
// import Sidebar from '../../../components/sidebar/Sidebar'
// import Navbar from '../../../components/navbar/Navbar'
// import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// import { db, storage } from "../../../firebase";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import Typography from '@mui/material/Typography';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
// import { collection, addDoc, serverTimestamp, doc, getDoc, updateDoc } from "firebase/firestore";
// import { useNavigate, useParams } from "react-router-dom"



// const New = ({inputs, title}) => {

//   const {nairachargesId} = useParams()

//   console.log("charge Id: ", nairachargesId)

//   const [ data, setData ] = useState({})
//   const [ isloading, setIsloading ] = useState(false)
//   // const [nairaCharges, setNairaCharges] = useState([])
//   // const [companyIntroduction, setCompanyIntroduction] = useState()
//   // const [selfIntroduction, setSelfIntroduction] = useState()
//   // const [leaveApproval, setLeaveApproval] = useState()
//   // const [employmentLetter, setEmploymentLetter] = useState()
//   // const [visaProcessingFee, setVisaProcessingFee] = useState() 

  
//   const navigate = useNavigate();


//   const Spinner = () => {
//     return(
//       <span className='spinner-container'>
//           <span className='loading-spinner'></span>
//       </span>
//     )
//   }



//   const handleInput = (event) =>{
   
//     setData(event.target.value)
//   }


//   // const handleUpdate = async(e) => {

//   //     e.preventDefault()
//   //     setIsloading(true)
//   //   try {
      
//   //     await updateDoc(doc(db, "nairacharges", nairachargesId), {


//   //         companyIntroduction : Number(companyIntroduction),
//   //         selfIntroduction : Number(selfIntroduction),
//   //         leaveApproval : Number(leaveApproval),
//   //         employmentLetter : Number(employmentLetter),
//   //         visaProcessingFee : Number(visaProcessingFee),
//   //   });

                  
//   //               setIsloading(false)

//   //               navigate('/nairacharges')

            
//   //         } catch (error) {

//   //           console.log('error:',error.message)

//   //         }

//   // }

  
//   const handleAdd = async(e) => {

//     e.preventDefault()
//     setIsloading(true)

//     try{
       
       
//        await addDoc(collection(db, "nairacharges"), {
//         ...data, charges : 'visaCharges',
//         timeStamp: serverTimestamp(),
        

//       });
      
//       setIsloading(false)

//       navigate('/nairacharges')
      
      
//     }catch (err) {
//       console.log(err);
//     } 

//   }



// //   const getNairaCharge = async() => {

// //     const docRef = doc(db, "nairacharges", nairachargesId);

// //     const docSnap = await getDoc(docRef);

        
// //     if (docSnap.exists()) {

// //       setNairaCharges(docSnap.data() )

// //     } else {
      
// //       console.log("No such document!");
// //     }


// // }

// // console.log('naira charges: ',nairaCharges)



 

// // useEffect(()=>{

// //   getNairaCharge()

// // }, [])



//   // console.log("charge Id: ", nairachargesId)

//   return (
//     <div className="new">
//       <Sidebar/>
//       <div className="newContainer">
//       <Navbar/>
//         <div className='top'>
//         <div className='topLeft'><h1>{title}</h1></div>
          
//           <div className='topRight'>
//           <Breadcrumbs aria-label="breadcrumb">
//             <Link underline="hover" color="inherit" href="/">
//               Home
//             </Link>
//             <Link
//               underline="hover"
//               color="inherit"
//               href="/nairacharges"
//             >
//               Charges List
//             </Link>
//             <Typography color="green">Add Naira Charges</Typography>
//           </Breadcrumbs>
//           </div>
//         </div>
        
//         <div className='bottom'>
//           {/* <div className="left">

//               <img
//                 src={file 
//                   ? URL.createObjectURL(file) 
//                   : "../images/hamburger.png"}
//                 alt=""
//                 className='imgs'
//                 resizeMode = "contain"
//               />
              
//           </div> */}
//           <div className='right'>
//           <form onSubmit={handleAdd}>

//           {inputs.map((input, index) =>

//           <div className='formInput' key={index.toString()} >
            
//             <label>{input.label}:</label>
//             <input type={input.type} placeholder={input.placeholder} onChange={handleInput} id={input.id} required/>
//           </div>

//           )}

//           <button  type='submit'>
//             {isloading && <span>...</span>}
//             {isloading ? <span>loading</span> : <span>Submit</span>}
//           </button>

//               {/* <div className='formInput' >
//                   <label>Company Introduction:</label>
//                   <input type="text" placeholder="N50000" value={companyIntroduction} onChange={e => setCompanyIntroduction(e.target.value)} id="companyIntroduction" required/>
//               </div>
              

//               <div className='formInput' >
//                   <label>Self Introduction:</label>
//                   <input type="text" placeholder="N70000" value={selfIntroduction}  onChange={e => setSelfIntroduction(e.target.value)} id="selfIntroduction" required/>
//               </div>

//               <div className='formInput' >
//                   <label>Leave Approval:</label>
//                   <input type="text" placeholder="N65000" value={leaveApproval}  onChange={e => setLeaveApproval(e.target.value)} id="leaveApproval" required/>
//               </div>

//               <div className='formInput' >
//                   <label>Employment Letter:</label>
//                   <input type="text" placeholder="N80000" value={employmentLetter}  onChange={e => setEmploymentLetter(e.target.value)} id="employmentLetter" required/>
//               </div>

//               <div className='formInput' >
//                   <label>Visa Processing Fee:</label>
//                   <input type="text" placeholder="N500000" value={visaProcessingFee}  onChange={e => setVisaProcessingFee(e.target.value)}id="visaProcessingFee" required/>
//               </div>

//               <button type='Update'>
//               {isloading && <span>...</span>}
//               {isloading ? <span>loading</span> : <span>Submit</span>}


//               </button> */}
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default New
