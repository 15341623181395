import React from 'react'
import './applicationTableParentInfo.scss' 


const  ApplicationTableParentInfo = ({ applicationData}) => {

  
  return (
            
    <div style={{display:'flex', flexDirection:'column', gap:50}}>

        <div>

            <div style={{display:'flex', flexDirection:'column', gap:20}}>

            {
                applicationData.fatherAlive === "fatherAlive"

                ?

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Father is:</span>
                    <span className='itemValue'>Alive</span>
                </div>

                :

                    <div style={{display:'flex', flexDirection:'row', gap:20}}>
                        <span style={{fontWeight:600, color:'gray'}}>Father is:</span>
                        <span className='itemValue'>Deceased</span>
                    </div>
            }    
            
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Firstname:</span>
                    <span className='itemValue'>{applicationData.fatherFirstname}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Lastname:</span>
                    <span className='itemValue'>{applicationData.fatherLastname}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Othername:</span>
                    <span className='itemValue'>{applicationData.fatherOthername}</span>
                </div>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Address:</span>
                    <span className='itemValue'>{applicationData.fatherAddress}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>DOB:</span>
                    <span className='itemValue'>{applicationData.dateOfBirthFather}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Status:</span>
                    <span className='itemValue'>{applicationData.fatherMaritalStatus}</span>
                </div>

            </div>
        </div>

        <div>
       
            <div style={{display:'flex', flexDirection:'column', gap:20}}>

            {
                applicationData.motherAlive === "motherAlive"

                ?

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Mother is:</span>
                    <span className='itemValue'>Alive</span>
                </div>

                :

                    <div style={{display:'flex', flexDirection:'row', gap:20}}>
                        <span style={{fontWeight:600, color:'gray'}}>Mother is:</span>
                        <span className='itemValue'>Deceased</span>
                    </div>
            }    
            
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Firstname:</span>
                    <span className='itemValue'>{applicationData.motherFirstname}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Lastname:</span>
                    <span className='itemValue'>{applicationData.motherLastname}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Othername:</span>
                    <span className='itemValue'>{applicationData.motherOthername}</span>
                </div>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Address:</span>
                    <span className='itemValue'>{applicationData.motherAddress}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>DOB:</span>
                    <span className='itemValue'>{applicationData.dateOfBirthMother}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Marital Status:</span>
                    <span className='itemValue'>{applicationData.motherMaritalStatus}</span>
                </div>
            
            </div>
            </div>
        </div>
   
  );
}

export default ApplicationTableParentInfo