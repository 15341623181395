
import './chart.scss'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
// import {db} from '../../firebase';
// import {getDoc, doc, collection, onSnapshot } from 'firebase/firestore'
import { collection, where, query, getDocs, onSnapshot } from 'firebase/firestore'
import React, {useState, useEffect} from 'react'
import { db } from '../../firebase'


const data = [
  {
    name: 'January',
    total: 2000,
  },
  {
    name: 'February',
    total: 4200,
  },
  {
    name: 'March',
    total: 2000,
  },
  {
    name: 'April',
    total: 6080,
  },
  {
    name: 'May',
    total: 5000,
  },
  {
    name: 'June',
    total: 2390,
  },
];


const Chart = ({aspect, title}) => {

const [diff, setDiff] = useState(null);
const [applicant, setApplicant] = useState(0)
const [application, setApplication] = useState(0)
const [datas, setDatas ] = useState([])
const [total, setTotal] = useState(0)


useEffect(() => {
  const fetchData = async() => {
    const today = new Date();
    const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1))
    const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2))
    console.log('last month:', lastMonth)
    console.log('previous month:', prevMonth)
    console.log('today:', today)

    const lastMonthQuery = query(
      collection(db, "visa"), 
      where("timeStamp", "<=", today),
      where("timeStamp", ">", lastMonth)
    );
    const prevMonthQuery = query(
      collection(db, "visa"), 
      where("timeStamp", "<=", lastMonth),
      where("timeStamp", ">", prevMonth)
    );

    

    const travellersQuery = collection(db, "travellers")
    const allTravellers = await getDocs(travellersQuery)
    setApplicant(allTravellers.docs.length)

    const visaQuery = collection(db, "visa")
    const allVisa = await getDocs(visaQuery)
    setApplication(allVisa.docs.length)


    const lastMonthData = await getDocs(lastMonthQuery)
    const prevMonthData = await getDocs(prevMonthQuery)

  
    setDiff((lastMonthData.docs.length - prevMonthData.docs.length) / (prevMonthData.docs.length) * 100)

      
  };

  fetchData();

}, [])


return (
    <div className='chart'>
        <div className='title'>{title}</div>
        <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart width={730} height={250} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
                <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
             
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" className='chartGrid' stroke='1'/>
            <Tooltip />
            
            <Area type="monotone" dataKey="total" stroke="#82ca9d" fillOpacity={1} fill="url(#total)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart

