import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import './applicationTableTravelHistory.scss' 


const  ApplicationTableTravelHistoryInfo = ({ applicationData}) => {

                                   
 

  return (
            
    <div className=''>

        {
            applicationData.travelledBefore === "travelled"

            ?

            
                <div style={{display:'flex', flexDirection:'column', gap:30, marginBottom:30}}>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Have You Travelled Before:</span>
                    <span className='itemValue'>Yes</span>
                </div>
                </div>
         

            :
                <div style={{display:'flex', flexDirection:'column', gap:30, marginBottom:30}}>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Have You Travelled Before:</span>
                    <span className='itemValue'>No</span>
                </div>
                </div>

        }



        {
            applicationData.travelledBefore === "travelled"

            ?

            
                <div style={{display:'flex', flexDirection:'row', gap:60, marginBottom:30}}>
                    <span style={{fontWeight:600, color:'gray'}}>Travel Document</span>
                    <span className='itemValue'>
                    <a href={applicationData.travelHistoryImage} download>View</a>
                    </span>
                </div>
         

            :
          ""

        }




        {
            applicationData.haveRelative === "haveRelative"

            ?
            
            <div className=''>

            <div style={{display:'flex', flexDirection:'column', gap:20, marginBottom:30}}>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Do you have Relative:</span>
                    <span className='itemValue'>Yes</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Relative name:</span>
                    <span className='itemValue'>{applicationData.relativeName}</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Relative Address:</span>
                    <span className='itemValue'>{applicationData.relativeAddress}</span>
                </div>
         
            </div>
            </div>

            :
            <div className=''>
            <div style={{display:'flex', flexDirection:'column', gap:20, marginBottom:30}}>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Do you have Relative:</span>
                    <span className='itemValue'>No</span>
                </div>
         
            </div>
            </div>

        }

        {
            applicationData.refused === "refused"

            ?
            <div style={{display:'flex', flexDirection:'column', gap:20, marginBottom:30}}>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span style={{fontWeight:600, color:'gray'}}>Have you been refused visa Before?:</span>
                    <span className='itemValue'>Yes</span>
                </div>
             
            </div>
            :
            <div style={{display:'flex', flexDirection:'column', gap:20, marginBottom:30}}>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                <span style={{fontWeight:600, color:'gray'}}>Have you been refused visa Before?:</span>
                    <span className='itemValue'>No</span>
                </div>
         
            </div>

        }



{
            applicationData.refused === "refused"

            ?

            
            <div className=''>
                <div style={{display:'flex', flexDirection:'column', gap:20, marginBottom:30}}>
                <div style={{display:'flex', flexDirection:'row', gap:60}}>
                    <span style={{fontWeight:600, color:'gray'}}>Decision Letter</span>
                    <span className='itemValue'>
                    <a href={applicationData.decisionLetterImage} download>View</a>
                    </span>
                </div>
                </div>
            </div>
         

            :
            <div className=''>
                <div style={{display:'flex', flexDirection:'column', gap:20, marginBottom:30}}>

                <div style={{display:'flex', flexDirection:'row', gap:20}}>
                    <span className='itemKey'></span>
                </div>
                </div>
            </div>

        }




        
    </div>
   
  );
}

export default ApplicationTableTravelHistoryInfo