import React from 'react'
import './applicationTableMaritalInfo.scss' 


const  ApplicationTableMaritalInfo = ({ applicationData, children}) => {

  
  return (
            
    <div>
       
        <div style={{display:'flex', flexDirection:'column', gap:20}}>
    
     
        {applicationData.marital !== "married"
        
          ?

          <div style={{display:'flex', flexDirection:'row', gap:20}}>
          <span style={{fontWeight:600, color:'gray'}}>Status:</span>
          <span className='itemValue'>Unmarried</span>
        </div>
         
          :

          <>
          <div style={{display:'flex', flexDirection:'row', gap:10}}>
            <span style={{fontWeight:600, color:'gray'}}>Status:</span>
            <span className='itemValue'>{applicationData.marital}</span>
          </div>

          <div style={{display:'flex', flexDirection:'row', gap:10}}>
            <span style={{fontWeight:600, color:'gray'}}>Spouse Name:</span>
            <span className='itemValue'>{applicationData.spouseName}</span>
          </div>
          <div style={{display:'flex', flexDirection:'row', gap:10}}>
            <span style={{fontWeight:600, color:'gray'}}>Spouse DOB:</span>
            <span className='itemValue'>{applicationData.spouseDob}</span>
          </div>
         

          <div style={{display:'flex', flexDirection:'row', gap:10}}>
            <span style={{fontWeight:600, color:'gray'}}>Children:</span>
            {children
              ? 
            <span className='itemValue'>has children</span>
            :
            <span className='itemValue'>No Children</span>
             }
           
          </div>

          </>

        
        }
        
       

         
              {/* {
                children !== "" ?
                
                children.map((child, index) => (
                  
                     <div key={index} style={{flexDirection:'column',padding:10}}>
            
                        <div style={{flexDirection:'row', justifyContent:"space-between"}}>
                          <span style={{fontSize:14, fontWeight:'400', color:'#000000', marginBottom:10}}>Child name :</span>
                          <span style={{fontSize:14, fontWeight:'bold', color:'#000000'}}>{child.firstname}</span>
                        </div>
                        <div style={{flexDirection:'row', justifyContent:"space-between"}}>
                          <span style={{fontSize:14, fontWeight:'400', color:'#000000', marginBottom:10}}>Date of Birth :</span>
                          <span style={{fontSize:14, fontWeight:'bold', color:'#000000'}}>{child.birthDay}</span>
                        </div>
                     
                      </div>
                  
                ))

                :
                    <div style={{flexDirection:'row', justifyContent:"space-between"}}>
                      <span style={{fontSize:18, fontWeight:'400', color:'#000000', marginBottom:10}}>Children :</span>
                      <span style={{fontSize:18, fontWeight:'bold', color:'#000000'}}>None</span>
                    </div>

              }  */}
        
      
        </div>
    </div>
   
  );
}

export default ApplicationTableMaritalInfo