import React, { useEffect, useState } from 'react'
import './dataTableApplications.scss'
import { DataGrid } from '@mui/x-data-grid';
import { userApplicationsColumns } from '../../data/dataSource';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot, query, orderBy, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase'



const DataTableUserApplication = ({applicantId}) => {

  const [ data, setData ] = useState([])

  // const applicantId = route.params

  const [ isLoading, setIsLoading ] = useState(true)


  const Spinner = () => {
    return(
      <div className='spinner-container'>
          <div className='loading-spinner'></div>
      </div>
    )
  }


  console.log('applicant details: ', applicantId)


  useEffect(() => {

    
    //LISTEN REAL TIME

    const applicationQuery = async() => {

      const applicationRef = (collection(db, "visa"));

      const q = query(applicationRef, where("userId", "==", applicantId));

      const querySnapshot = await getDocs(q);

      let list = []

      querySnapshot.forEach((doc) => {

        list.push({ id: doc.id, ...doc.data(), timeStamp: doc.data().timeStamp.toDate()})

        console.log('the list: ', list)

        setData(list)
        setIsLoading(false)
        
      });


    }
    
    applicationQuery()   
  },[])

  console.log('user Application: ',data)


  const handleDelete = async (id) => {

    try{

      await deleteDoc(doc(db, "visa", id));
      setData(data.filter((item) => item.id !== id))

    }catch(err){

      console.log(err)

    }
   
  }

  const actionColumn = [

    { field: 'action', headerName: 'Action', width: 160, 
    
            renderCell:(params) => {
                return(
                    <div className='cellAction'>
                       <Link to={`/applications/${params.row.id}`} style={{textDecoration:"none"}}>
                          <div className="viewbutton">View</div>
                       </Link>
                       <div className='deletebutton' onClick={() => handleDelete(params.row.id)}>Delete</div>
                   
                    </div>
                )
            }
    }
      
  ]  

  return (
    <div className="datatable">
     
      {/* {isLoading ? <Spinner/> :  */}
        <DataGrid
            className="userApplicationTableGrid"
            rows={data}
            columns={userApplicationsColumns.concat(actionColumn)}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            getRowId={(row) => row.visaId}
            
        />
    {/* // } */}

      
    </div>
  )
}

export default DataTableUserApplication