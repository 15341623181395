import Home from "./pages/home/Home";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useContext } from 'react';
import { Login } from "./pages/login/Login";
import { ForgotPwd } from "./pages/forgotPwd/ForgotPwd"
import RidersList from "./pages/riders/list/List";
import RiderSingle from "./pages/riders/single/Single";
import RiderNew from "./pages/riders/new/New"; 
import DispatchList from "./pages/dispatch/list/List";
import DispatchSingle from "./pages/dispatch/single/Single";
import DispatchNew from "./pages/dispatch/new/New"; 
import RideHistoryList from "./pages/rideHistory/list/List";
import RideHistorySingle from "./pages/rideHistory/single/Single";
import RideList from "./pages/rides/list/List";
import RideSingle from "./pages/rides/single/Single";
import DriversList from "./pages/drivers/list/List";
import DriverSingle from "./pages/drivers/single/Single";
import DriversNew from "./pages/drivers/new/New"; 
import NotificationList from "./pages/notifications/list/List";
import NotificationSingle from "./pages/notifications/single/Single";
import NotificationNew from "./pages/notifications/new/New"; 
import ReviewsList from "./pages/reviews/list/List";
import ReviewsSingle from "./pages/reviews/single/Single";
import AnalyticsList from "./pages/analytics/list/List";
import AnalyticsSingle from "./pages/analytics/single/Single";

import SettingsList from "./pages/settings/list/List";
import SettingsSingle from "./pages/settings/single/Single";
import SettingsNew from "./pages/settings/new/New"; 

import MessagesList from "./pages/messages/list/List";
import MessagesSingle from "./pages/messages/single/Single";
import MessagesNew from "./pages/messages/new/New"; 
import { dispatchInput, driversInput, riderInput, notificationInput, settingsInput, applicantInput, chargesInput, nairaChargesInput,messagesInput, contactInput, announcementInput} from "./data/formSource";
import './style/dark.scss'
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";

import ApplicantList from "./pages/visaApplicant/list/List"
import ApplicantSingle from "./pages/visaApplicant/single/Single"
import ApplicantNew from "./pages/visaApplicant/new/New"

import ApplicationList from "./pages/visaApplication/list/List"
import ApplicationSingle from "./pages/visaApplication/single/Single"

import ApplicationHistoryList from "./pages/applicationHistory/list/List"
import ApplicationHistorySingle from "./pages/applicationHistory/single/Single"

import ChargesList from "./pages/charges/list/List"
import ChargesNew from "./pages/charges/new/New"
import ChargesEdit from "./pages/charges/edit/Edit"
import ChargesSingle from "./pages/charges/single/Single"

import NairaChargesList from "./pages/nairaCharges/list/List"
import NairaChargesNew from "./pages/nairaCharges/new/New"
import NairaChargesEdit from "./pages/nairaCharges/edit/Edit"
import NairaChargesSingle from "./pages/nairaCharges/single/Single"

import PaymentList from './pages/visaPayments/list/List'
import PaymentSingle from './pages/visaPayments/single/Single'

import ChatList from './pages/chats/list/List'
import ChatSingle from './pages/chats/single/Single'

import ContactList from "./pages/contact/list/List"
import ContactNew from "./pages/contact/new/New"
import ContactSingle from "./pages/contact/single/Single"

import AnnouncementList from "./pages/announcement/list/List"
import AnnouncementNew from "./pages/announcement/new/New"
import AnnouncementSingle from "./pages/announcement/single/Single"




function App() {
  
  const { darkMode } = useContext(DarkModeContext)
  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({children}) => {
    return currentUser ? children : <Navigate to="/login"/>
  }

  return (
    <div className= {darkMode ? "app dark" : "app"}>
       <BrowserRouter>
        <Routes>
          <Route path="/">
          <Route path="login" element={<Login/>}/>
          <Route path="forgotPwd" element={<ForgotPwd/>}/>
            <Route index element={
            <RequireAuth>
                 <Home user={currentUser}/>
            </RequireAuth>
            
           } />

            <Route path="riders">
              <Route index element={
                <RequireAuth>
                 <RidersList />
              </RequireAuth>
              }/>
              <Route path=":riderId" element={
              <RequireAuth>
                  <RiderSingle/>
              </RequireAuth>
              
              }/>
              <Route path="new" element={
                <RequireAuth>
                  <RiderNew inputs={riderInput} title="Add New Rider"/>
                </RequireAuth>
              }/>
            </Route>

            <Route path="applicants">
              <Route index element={
                <RequireAuth>
                 <ApplicantList />
              </RequireAuth>
              }/>
              <Route path=":applicantId" element={
              <RequireAuth>
                  <ApplicantSingle/>
              </RequireAuth>
              
              }/>
              <Route path="new" element={
                <RequireAuth>
                  <ApplicantNew inputs={applicantInput} title="Add New Applicant"/>
                </RequireAuth>
              }/>
            </Route>

            <Route path="applications">
              <Route index element={
                <RequireAuth>
                  <ApplicationList/>
                </RequireAuth>
              }/>
              <Route path=":applicationId" element={
              <RequireAuth>
                  <ApplicationSingle/>
              </RequireAuth>
              }/>
            
            </Route>

            <Route path="applicationHistory">
              <Route index element={
                <RequireAuth>
                  <ApplicationHistoryList/>
                </RequireAuth>
              }/>
              <Route path=":applicationHistoryId" element={
              <RequireAuth>
                  <ApplicationHistorySingle/>
              </RequireAuth>
              }/>
            
            </Route>
            

            <Route path="charges">
              <Route index element={
                <RequireAuth>
                  <ChargesList/>
                </RequireAuth>
              }/>
              <Route path=":chargesId" element={
              <RequireAuth>
                  <ChargesSingle/>
              </RequireAuth>
              }/>

              <Route path="edit/:chargeId" element={
                <RequireAuth>
                  <ChargesEdit title="Edit Dollar Charges"/>
                </RequireAuth>
              }/>

              <Route path="new" element={
                <RequireAuth>
                  <ChargesNew inputs={chargesInput} title="Add New Charges"/>
                </RequireAuth>
              }/>
            
            </Route>


            <Route path="nairacharges">
              <Route index element={
                <RequireAuth>
                  <NairaChargesList/>
                </RequireAuth>
              }/>
              <Route path=":nairachargesId" element={
              <RequireAuth>
                  <NairaChargesSingle/>
              </RequireAuth>
              }/>

              <Route path="edit/:nairachargesId" element={
                <RequireAuth>
                  <NairaChargesEdit title="Edit Naira Charges"/>
                </RequireAuth>
              }/>

              <Route path="new" element={
                <RequireAuth>
                  <NairaChargesNew inputs={nairaChargesInput} title="Add New Charges"/>
                </RequireAuth>
              }/>
            
            </Route>


            <Route path="payments">
              <Route index element={
                <RequireAuth>
                  <PaymentList/>
                </RequireAuth>
              }/>
              <Route path=":paymentId" element={
              <RequireAuth>
                  <PaymentSingle/>
              </RequireAuth>
              }/>

            </Route>
            
            
            <Route path="dispatch">
              <Route index element={
               <RequireAuth>
                <DispatchList />
               </RequireAuth>
              }/>
              <Route path=":dispatchId" element={
                <RequireAuth>
                 <DispatchSingle/>
                </RequireAuth>
              }/>
              <Route path="new" element={
                <RequireAuth>
                  <DispatchNew inputs={dispatchInput} title="Add New Dispatch Rider"/>
                </RequireAuth>
              }/>
              </Route> 
            </Route>
            
            <Route path="rides">
              <Route index element={
                <RequireAuth>
                  <RideList/>
                </RequireAuth>
              }/>
              <Route path=":orderId" element={
              <RequireAuth>
                  <RideSingle/>
              </RequireAuth>
              }/>
            
            </Route>

            <Route path="rideHistory">
              <Route index element={
                <RequireAuth>
                  <RideHistoryList/>
                </RequireAuth>
              }/>
              <Route path=":rideHistoryId" element={
              <RequireAuth>
                  <RideHistorySingle/>
              </RequireAuth>
              }/>
            
            </Route>

            <Route path="drivers">
              <Route index element={
                <RequireAuth>
                  <DriversList/>
                </RequireAuth>
              }/>
              <Route path=":driverId" element={
              <RequireAuth>
                  <DriverSingle/>
              </RequireAuth>
              }/>

              <Route path="new" element={
                <RequireAuth>
                  <DriversNew inputs={driversInput} title="Add New Driver"/>
                </RequireAuth>
              }/>
            
            </Route>
            
            <Route path="notifications">
              <Route index element={
               <RequireAuth>
                <NotificationList />
               </RequireAuth>
              }/>
              <Route path=":notificationId" element={
                <RequireAuth>
                 <NotificationSingle/>
                </RequireAuth>
              }/>
              <Route path="new" element={
                <RequireAuth>
                  <NotificationNew inputs={notificationInput} title="Add New Dish"/>
                </RequireAuth>
              }/>
              </Route>

            <Route path="reviews">
              <Route index element={
               <RequireAuth>
                <ReviewsList />
               </RequireAuth>
              }/>
              <Route path=":reviewId" element={
                <RequireAuth>
                 <ReviewsSingle/>
                </RequireAuth>
              }/>
            
              </Route>

              <Route path="analytics">
              <Route index element={
               <RequireAuth>
                <AnalyticsList />
               </RequireAuth>
              }/>
              <Route path=":analyticsId" element={
                <RequireAuth>
                 <AnalyticsSingle/>
                </RequireAuth>
              }/>
           
              </Route>

              <Route path="settings">
              <Route index element={
               <RequireAuth>
                <SettingsList />
               </RequireAuth>
              }/>
              <Route path=":settingsId" element={
                <RequireAuth>
                 <SettingsSingle/>
                </RequireAuth>
              }/>
              <Route path="new" element={
                <RequireAuth>
                  <SettingsNew inputs={settingsInput} title="Make user an Admin"/>
                </RequireAuth>
              }/>
              </Route>

              <Route path="messages">
              <Route index element={
               <RequireAuth>
                <MessagesList />
               </RequireAuth>
              }/>
              <Route path=":messageId" element={
                <RequireAuth>
                 <MessagesSingle/>
                </RequireAuth>
              }/>
              <Route path="new" element={
                <RequireAuth>
                  <MessagesNew inputs={messagesInput} title="Add New Message"/>
                </RequireAuth>
              }/>
              </Route>


              
              <Route path="chats">

              <Route index element={
               <RequireAuth>
                <ChatList />
               </RequireAuth>
              }/>
           
              <Route path=":chatId" element={
                <RequireAuth>
                 <ChatSingle/>
                </RequireAuth>
              }/>
            
              </Route>

              <Route path="contacts">
              <Route index element={
                <RequireAuth>
                 <ContactList />
              </RequireAuth>
              }/>
              <Route path=":contactId" element={
              <RequireAuth>
                  <ContactSingle/>
              </RequireAuth>
              
              }/>
              {/* <Route path="new" element={
                <RequireAuth>
                  <ContactNew inputs={contactInput} title="Send Contact Information"/>
                </RequireAuth>
              }/> */}
            </Route>

            <Route path="announcement">
              <Route index element={
                <RequireAuth>
                 <AnnouncementList />
              </RequireAuth>
              }/>
              <Route path=":announceId" element={
              <RequireAuth>
                  <AnnouncementSingle/>
              </RequireAuth>
              
              }/>
              <Route path="new" element={
                <RequireAuth>
                  <AnnouncementNew inputs={announcementInput} title="Add New Announcement"/>
                </RequireAuth>
              }/>
            </Route>

        </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
