import React, { useEffect, useState } from 'react';
import './dataTableSettings.scss';
import { DataGrid } from '@mui/x-data-grid';
import { settingsColumns } from '../../data/dataSource';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot, getDoc} from "firebase/firestore";
import { db, functions } from '../../firebase';
import {  httpsCallable } from "firebase/functions";
// import { doc, getDoc, getDocs, where, collection, query } from "firebase/firestore"


const DataTable = () => {

const [ data, setData ] = useState([])

const [ isLoading, setIsLoading ] = useState(true)

const [ email, setEmail ] = useState("")

const Spinner = () => {

    return(

      <div className='spinner-container'>

          <div className='loading-spinner'></div>

      </div>

    )
    
  }


  const getAdminEmail = async(id) => {

    const docRef = doc(db, "admins", id);

    const docSnap = await getDoc(docRef);

  
    
    if (docSnap.exists()) {

      setEmail({ email: docSnap.data().email})
      // console.log(userData)
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
}


console.log('new email: ', email)


  useEffect(() => {
   
    
//LISTEN REAL TIME

    const unsub = onSnapshot(collection(db, "admins"), (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data(), email: doc.data().email})
      })
      setData(list)
      setIsLoading(false)
    }, 
      (error) => {
        console.log(error)
      }
    );

    return () => {
      unsub();
    }

//END LISTEN REAL TIME    

  },[])

  console.log(data)

  const handleDelete = async(id) => {

    getAdminEmail(id)

    const adminRoleRomove = httpsCallable(functions, 'removeAdminRole');

    adminRoleRomove({email : email}).then(result => {

      console.log(result.data.message)
      alert(JSON.stringify(result.data.message))

    })

    // await deleteDoc(doc(db, "admins", id));
    // setData(data.filter((item) => item.id !== id))  

    }

  const actionColumn = [

    { field: 'action', headerName: 'Action', width: 130, 
    
            renderCell:(params) => {
                return(
                    <div className='cellAction'>
                       <Link to={`/settings/${params.row.id}`} style={{textDecoration:"none"}}>
                          <div className="viewbutton">View</div>
                       </Link>
                       
                        <div className='deletebutton' onClick={() => handleDelete(params.row.id)}>Remove</div>
                    </div>
                )
            }
    }
      
  ]  

  return (
    <div className="datatable">
      <div className='datatableTitle'>
        Visadoc Admins
        <Link to="/settings/new"  style={{textDecoration:"none"}} >
          <div className='link'>
            Add New Admin
          </div>
        </Link>
      </div>
      {isLoading 
      ? <Spinner/>
      :<DataGrid
          className="userTableGrid"
          rows={data}
          columns={settingsColumns.concat(actionColumn)}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      }
    </div>
  )
}

export default DataTable