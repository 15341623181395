import React, { useState } from 'react'
import './new.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { db, storage } from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { collection, addDoc, serverTimestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom"



const New = ({inputs, title}) => {



  const [ data, setData ] = useState({})
  const [ isloading, setIsloading ] = useState(false)
 
  const navigate = useNavigate();


  const Spinner = () => {
    return(
      <span className='spinner-container'>
          <span className='loading-spinner'></span>
      </span>
    )
  }

  


  const handleInput = (e) =>{
    const id = e.target.id;
    const value = e.target.value;

    setData({...data, [id]:value})
  }

  
  const handleAdd = async(e) => {

    e.preventDefault()
    setIsloading(true)

    try{
       
       
       await addDoc(collection(db, "announcement"), {
        ...data,
        timeStamp: serverTimestamp(),
        

      });
      
      setIsloading(false)

      navigate('/announcement')
      
      
    }catch (err) {
      console.log(err);
    } 

  }



  return (
    <div className="new">
      <Sidebar/>
      <div className="newContainer">
      <Navbar/>
        <div className='top'>
        <div className='topLeft'><h1>{title}</h1></div>
          
          <div className='topRight'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link 
              underline="hover"
              color="inherit"
              href="/announcement"
            >
              Dollar Charges List
            </Link>
            <Typography color="green">Add Announcement</Typography>
          </Breadcrumbs>
          </div>
        </div>
        
        <div className='bottom'>
       
          <div className='right'>
          <form onSubmit={handleAdd}>

          {inputs.map((input, index) =>

            <div className='formInput' key={index.toString()} >
              
              <label>{input.label}:</label>
              <input type={input.type} placeholder={input.placeholder} onChange={handleInput} id={input.id} required rows='4'/>
            </div>

          )}

            <button  type='submit'>
              {isloading && <span>...</span>}
              {isloading ? <span>loading</span> : <span>Submit</span>}
            </button>

            

            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default New
