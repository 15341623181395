import React from 'react'
import './applicationTableTravelInfo.scss' 


const  ApplicationTableTravelInfo = ({ applicationData}) => {

  
  return (
            
    <div>
       
        <div style={{display:'flex', flexDirection:'column', gap:20}}>

          <div style={{display:'flex', flexDirection:'row', gap:20}}>
            <span style={{fontWeight:600, color:'gray'}}>Destination:</span>
            <span className='itemValue'>{applicationData.country}</span>
          </div>
          <div style={{display:'flex', flexDirection:'row', gap:20}}>
            <span style={{fontWeight:600, color:'gray'}}>Purpose of Travel:</span>
            <span className='itemValue'>{applicationData.travelPurpose}</span>
          </div>
          <div style={{display:'flex', flexDirection:'row', gap:20}}>
            <span style={{fontWeight:600, color:'gray'}}>Visa Type:</span>
            <span className='itemValue'>{applicationData.visaType}</span>
          </div>
      
        </div>
    </div>
   
  );
}

export default ApplicationTableTravelInfo