import React from 'react'
// import './applicationTableEmploymentInfo.scss' 


const  ApplicationTablePaymentInfo = ({ applicationData}) => {

  return (
            
    <div className=''>

        <div style={{display:'flex', flexDirection:'column', gap:20}}>

              
            <div style={{display:'flex', flexDirection:'row', gap:20}}>
                <span style={{fontWeight:600, color:'gray'}}>Visa Processing Fee:</span>
                <span className='itemValue'>{applicationData.total} {applicationData.currency}</span>
            </div>

            <div style={{display:'flex', flexDirection:'row', gap:20}}>
                <span style={{fontWeight:600, color:'gray'}}>Payment ID:</span>
                <span  style={{fontSize:13}}>{applicationData.paymentID}</span>
            </div>
            

            {
                applicationData.total
                ?
                <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, alignItems:'center', gap:20}}>
                    <div style={{display:'flex', flexDirection:'row', gap:20}}>
                        <span style={{fontWeight:'bold', color:'#00000088'}}>Payment Receipt:</span>
                    </div>
                    
                    <div  style={{display:'flex', flexDirection:'row', marginLeft:10, gap:40}}>
                    <a href={applicationData.paymentReceiptImage} download>View</a>
                    </div>
                  
                </div>
                :

                ""
            }  

        
        </div>
    </div>
   
  );
}

export default ApplicationTablePaymentInfo