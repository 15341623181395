import React, { useState, useEffect } from 'react'
import './new.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import {serverTimestamp, setDoc, doc } from "firebase/firestore";
import { auth, db, } from "../../../firebase";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { functions } from '../../../firebase';
import {  httpsCallable } from "firebase/functions";



const New = ({inputs, title}) => {

  const [ data, setData ] = useState({})
  const [ error, setError ] = useState("")


  const handleInput = (e) =>{
    const id = e.target.id;
    const value = e.target.value;

    setData({...data, [id]:value})
  }

  
  const handleAddAdmin = async(e) => {
    e.preventDefault()

    if(data.email === ""){
      return setError("Field is required")
    }

    const adminEmail = data.email;
    const adminRole = httpsCallable(functions, 'addAdminRoleAndDataUpdated');

    adminRole({email : adminEmail}).then(result => {

      console.log(result.data.message)
      alert(JSON.stringify(result.data.message))

    })

    


    // try{

    //   const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
 
    //   await setDoc(doc(db, "admins", res.user.uid), {
    //      ...data,
    //     timeStamp: serverTimestamp(),
        
    //   }
    //   );
    //   console.log()
      
    // }catch (err) {
    //   console.log(err);
    // } 

  }

  return (
    <div className="new">
      <Sidebar/>
      <div className="newContainer">
      <Navbar/>
        <div className='top'>
          <div className='topLeft'><h1>New Visadoc Admin</h1></div>
          
          <div className='topRight'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/settings"
            >
              Admin List
            </Link>
            <Typography color="green">New Admin</Typography>
          </Breadcrumbs>
          </div>
        </div>
        <div className='bottom'>
         
          <div className='right'>
            <form onSubmit={handleAddAdmin}>
              {inputs.map((input, index) =>

                       <div className='formInput' key={index.toString()} >
                        
                       <label>{input.label}:</label>
                       <input type={input.type} placeholder={input.placeholder} onChange={handleInput} id={input.id}/>
                       {error &&
                        <p>{error}</p>
                       }
                     </div>
              
              )}

             <button  type='submit'>Make Admin</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default New
