import React, { useEffect, useState } from 'react'
import './single.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import Chart from '../../../components/chart/Chart'
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { useParams } from "react-router-dom"
import { db } from '../../../firebase'
import UserOrderTable from '../../../components/table/UserOrderTable'

const Single = ({user}) => {

  const {messageId} = useParams()

  const [ message, setMessage ] = useState("")

  // const [ orderData, setOrderData ] = useState([])

  const getMessage = async() => {

      const docRef = doc(db, "messages", messageId);

      const docSnap = await getDoc(docRef);

    
      
      if (docSnap.exists()) {

        setMessage(docSnap.data())
        // console.log(userData)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
  }

  // const updateMessage = async() => {
  
  //   const msgRef = doc(db, "messages", messageId);
    
  //    const updateMsg = updateDoc(msgRef, {
  //     message: "read"
  //   });
    
       
  // }



  useEffect(()=>{
    getMessage()
  }, [])


  // useEffect(() => {
    
  //   //LISTEN REAL TIME

  //   const orderQuery = async() => {

  //     const orderRef = (collection(db, "order"));
  //     const q = query(orderRef, where("userId", "==", customerId));

  //     const querySnapshot = await getDocs(q);
  //       let list = []
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       list.push(doc.data())
  //       // console.log(doc.id, " => ", doc.data());
  //       console.log(list)

  //       setOrderData(list)
  //       // console.log(orderData)
  //     });

  //     // setOrderData(doc.data())
  //     // console.log(orderData)

  //   }
    
  //   orderQuery()   
  // },[])
 

  return (
    <div className='single'>
      <Sidebar/>
      <div className='singleContainer'>
        <Navbar/>
        <div className="top">
          <div className="left">
          <div style={{display: 'flex', flexDirection:'row', gap:10, marginBottom:10}}>
            <h2 className='title'>Message Status:</h2>
            <h2 className='title'>{message.status}</h2>
            </div>
            <div className='item'>
           
                <div className="details">
                 
                  
                  <div style={{display: 'flex', flexDirection:'row', gap:20, marginBottom:20}}>
                  <span className='itemKey'>Name:</span>
                  <div style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemValue'>{message.firstname}</span>
                    <span className='itemValue'>{message.surname}</span>
                  </div>
                  </div>
                  <div  style={{display: 'flex', flexDirection:'row', gap:10}}>
                    <span className='itemKey'>Message:</span>
                    <span className='itemValue'>{message.message}</span>
                  </div>
                
                </div>
            </div>
          </div>
         
        </div>
        
      </div>
    </div>
  )
}

export default Single