import React, { useEffect, useState } from 'react'
import './featured.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { collection, where, query, getDocs, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase'

const Featured = () => {

    const [todayRevenue, setTodayRevenue] = useState([]);
    const [data, setData] = useState([])
    const [lastMonthData, setLastMonthData] = useState([])
 

    useEffect(() => {


        const today = new Date();

        console.log('today: ', today)

        const todayRevenueQuery = query(
                collection(db, "visa"), 
                where("timeStamp", "==", today),
                where("currencey", "==", "NGN")
                // where("constant", "<=", 'active')
        );

      

    
        const unsub = onSnapshot(todayRevenueQuery, (snapShot) => {
          let list = [];
          
          snapShot.docs.forEach((doc) => {
            list.push({total:doc.data().total})
          })
          setData(list)

          console.log('list:',data)
          const revenue = list.reduce((tot, currentValue) => tot = tot + currentValue.total,0);
          setTodayRevenue(revenue)
          // console.log('revenue:', todayRevenue)
          }, 
            (error) => {
              console.log(error)
            }
          );
          // console.log('revenue:', todayRevenue)

          return () => {
            unsub();
          }
        
    }, [])


// console.log('data: ', data)
    
    useEffect(() => {


      const today = new Date();
      const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1))

      // console.log('last month: ', lastMonth)

      const lastMonthQuery = query(
          collection(db, "visa"), 
          where("timeStamp", "<=", today),
          where("timeStamp", ">", lastMonth),
          where('currency', "==", "USD")
        );

  
        const lastMonthRevenue = onSnapshot(lastMonthQuery, (snapShot) => {

          let list = [];
          
          snapShot.docs.forEach((doc) => {

            list.push({total:doc.data().total})
            
          })

          // console.log('list lastmonth:',list)

          const revenue = list.reduce((tot, currentValue) => tot = tot + currentValue.total,0);

          setLastMonthData(list)
          
         

          }, 

            (error) => {

              console.log(error)

            }

          );

          // console.log('revenue:', lastMonthData)
    
        return () => {

          lastMonthRevenue();

        }
      
  }, [])

  // console.log('last month data:', lastMonthData)

  return (
    <div className='featured'>
        <div className='top'>
            <h1 className='title'>Today Revenue</h1>
            <MoreVertIcon fontSize="small"/>

        </div>
        <div className='bottom'>
            <div className="featuredChart">
                <CircularProgressbar value={100} text={"30%"} strokeWidth={10}/>
                <CircularProgressbar
                  color="neutral"
                  determinate
                  size="lg"
                  // text={"30%"}
                  value={36}
                />
            </div>
            <p className="title">Total sales made today.</p>
            <p className="amount">${todayRevenue}</p>
            <p className="description">Previous transaction processing, last payment may not be included</p>
            <div className="summary">
                <div className="item">
                    <div className="itemTitle">Target</div>
                    <div className="itemResult negative">
                        <KeyboardArrowDownOutlinedIcon fontSize='small'/>
                        <div className="resultAmount">$2000</div>
                    </div>
                </div>
                <div className="item">
                    <div className="itemTitle">Last Week</div>
                    <div className="itemResult positive">
                        <KeyboardArrowUpOutlinedIcon fontSize='small'/>
                        <div className="resultAmount">$2000</div>
                    </div>
                </div>
                <div className="item">
                    <div className="itemTitle">Last Month</div>
                    <div className="itemResult positive">
                        <KeyboardArrowUpOutlinedIcon fontSize='small'/>
                        <div className="resultAmount">${lastMonthData}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Featured